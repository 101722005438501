import {
    cookieLocation,
    cookieLocationBrandGuest,
    useAuthCookieHandler,
} from '@/hooks/useAuthCookieHandler/useAuthCookieHandler';
import { getUser, getUserRole } from '@/redux/user';
import { useLazyMeQuery } from '@/services/innovorder/auth/auth.endpoints';
import { useUpdatePasswordMutation } from '@/services/innovorder/user/user.endpoints';
import { UpdatePasswordPayload } from '@/services/innovorder/user/user.type';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

const ERROR_CODES = {
    USER_NOT_FOUND_OR_INCORRECT_PASSWORD: 'user_not_found_or_incorrect_password',
};

const useUpdatePasswordVM = () => {
    const {
        handleSubmit,
        control,
        formState: { isValid },
        getValues,
        setError,
        reset,
    } = useForm<{
        currentPassword: string;
        newPassword: string;
        confirmPassword: string;
    }>({
        mode: 'onChange',
        defaultValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
    });
    const role = useSelector(getUserRole);
    const user = useSelector(getUser);
    const [updatePassword, { isLoading, error, isSuccess }] = useUpdatePasswordMutation();
    const [me] = useLazyMeQuery();
    const [cookies] = useCookies();
    const { isGuestBrand } = useAuthCookieHandler();

    useEffect(() => {
        if (error) {
            const errorData = (error as FetchBaseQueryError).data as { code: string; message: string };
            setError(
                errorData.code === ERROR_CODES.USER_NOT_FOUND_OR_INCORRECT_PASSWORD ? 'currentPassword' : 'newPassword',
                {
                    message: errorData.message,
                },
            );
        }
    }, [error]);

    useEffect(() => {
        if (isSuccess) {
            reset();
        }
    }, [isSuccess]);

    const onSubmit = async (data: { currentPassword: string; newPassword: string; confirmPassword: string }) => {
        let body = {
            userId: user?.userId,
            oldPassword: data.currentPassword,
            newPassword: data.newPassword,
            role,
        } as UpdatePasswordPayload;
        if (isGuestBrand) {
            const result = await me({ token: cookies[cookieLocation]?.accessToken }).unwrap();
            body = {
                ...body,
                userId: result.userId,
                role: result.role,
                token: cookies[cookieLocation]?.accessToken,
            } as UpdatePasswordPayload;
        }
        if (role && user?.userId) {
            updatePassword(body);
        }
    };

    return {
        handleSubmit,
        onSubmit,
        control,
        isValid,
        isLoading,
        getValues,
        isSuccess,
    };
};

export default useUpdatePasswordVM;
