import React from 'react';
import { Either } from '@/utils/typescript';
import type { AccessFlags } from '@/services/innovorder/auth/auth.type';
import { BrandOptionName } from '@/services/innovorder/brand/brand.type';
import { UserRoles } from '@/services/innovorder/user/user.type';
import { ModalProps } from '@/components/atoms/Modal';

export enum Routes {
    Login = '/login',
    Logout = '/logout',
    Home = '/',
    Analytics = '/analytics',
    Orders = '/orders',
    OrderDetails = '/orders/:orderId',
    ReportGenerationsHistory = '/report_generations_history',
    SalesSummary = '/exports/sales_summary',
    Billing = '/billing',
    IOPay = '/io-pay',
    IOPayConfiguration = '/io-pay/configuration',
    IOPayTransactions = '/io-pay/transactions',
    IOPayTransactionDetail = '/io-pay/transactions/:transactionId',
    IOPayTransfers = '/io-pay/transfers',
    IOPayTransferDetail = '/io-pay/transfers/:transferId',
    IOPayReports = '/io-pay/reports',
    ConfigUbereats = '/brands/:brandId/restaurants/:restaurantId/config/ubereats',
    ConfigDeliveroo = '/brands/:brandId/restaurants/:restaurantId/config/deliveroo',
    ConfigDeliverect = '/brands/:brandId/restaurants/:restaurantId/config/deliverect',
    ConfigCrous = '/brands/:brandId/restaurants/:restaurantId/config/crous',
    CreateWebhook = '/config/:brandId/:restaurantId/webhooks/create',
    EditWebhook = '/config/:brandId/:restaurantId/webhooks/edit/:webhookId',
    ConfigExternalPos = '/config/:brandId/:restaurantId/external_pos',
    ConfigIOPay = '/config/:brandId/:restaurantId/iopay',
    ConfigEdenred = '/config/:brandId/:restaurantId/edenred',
    ConfigPaygreen = '/config/:brandId/:restaurantId/paygreen',
    PermissionDenied = '/permission-denied',
    NotFound = '/not-found',
    Integrations = '/brands/:brandId/integrations',
    StripeIntegration = '/brands/:brandId/integrations/stripe',
    GrantsAndEntranceFees = '/brands/:brandId/grants_and_entrance_fees',
    Options = '/brands/:brandId/options',
    Groups = '/brands/:brandId/groups',
    BrandAdminTools = '/brands/:brandId/admin_tools',
    AutoBilling = '/brands/:brandId/auto_billing',
    AutoBillingReferential = '/brands/:brandId/auto_billing/referential',
    CreateBillingEntity = '/brands/:brandId/auto_billing/referential/entities/create',
    EditBillingEntity = '/brands/:brandId/auto_billing/referential/entities/:billingEntityCode',
    EntranceFee = '/brands/:brandId/grants_and_entrance_fees/entrance_fee/:entranceFeeId',
    BrandCustomer = '/brands/:brandId/customers',
    CreateEntranceFee = '/brands/:brandId/grants_and_entrance_fees/entrance_fee/create',
    Grant = '/brands/:brandId/grants_and_entrance_fees/grant/:grantId',
    CreateGrant = '/brands/:brandId/grants_and_entrance_fees/grant/create',
    PricingRule = '/brands/:brandId/grants_and_entrance_fees/pricing_rule/:pricingRuleId',
    CreatePricingRule = '/brands/:brandId/grants_and_entrance_fees/pricing_rule/create',
    RestaurantInfos = ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/info',
    RestaurantChannels = ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/channels',
    RestaurantChannelsV2 = '/brands/:brandId/restaurants/:restaurantId/channels',
    RestaurantUsers = ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/users',
    OldRestaurantMenus = ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/menus',
    RestaurantUserInfos = ':backofficeUrl/home/restaurants/:restaurantId/info',
    RestaurantUserChannels = ':backofficeUrl/home/restaurants/:restaurantId/channels',
    OldRestaurantUserMenus = ':backofficeUrl/home/restaurants/:restaurantId/menus',
    RestaurantTickets = ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/tickets',
    RestaurantMedias = ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/media',
    RestaurantUserMedias = ':backofficeUrl/home/restaurants/:restaurantId/media',
    RestaurantIntegrations = ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/integrations',
    RestaurantPayments = '/brands/:brandId/restaurants/:restaurantId/payment_methods',
    RestaurantOptions = '/brands/:brandId/restaurants/:restaurantId/options',
    RestaurantPricingRevaluation = '/brands/:brandId/restaurants/:restaurantId/pricing_revaluation',
    RestaurantMenu = '/brands/:brandId/restaurants/:restaurantId/menus/:menuId',
    Brands = ':backofficeUrl/home/brands',
    BrandsV2 = '/brands',
    BrandInfo = ':backofficeUrl/home/brands/:brandId/info',
    BrandChannels = ':backofficeUrl/home/brands/:brandId/channels',
    BrandChannelsV2 = '/brands/:brandId/channels',
    BrandMenusBOV1 = ':backofficeUrl/home/brands/:brandId/menus',
    BrandRestaurants = ':backofficeUrl/home/brands/:brandId/restaurants',
    BrandTickets = ':backofficeUrl/home/brands/:brandId/tickets',
    BrandReportStatus = ':backofficeUrl/home/brands/:brandId/report_status',
    BrandUsers = ':backofficeUrl/home/brands/:brandId/users',
    BrandUsersV2 = '/brands/:brandId/users',
    BrandMedias = ':backofficeUrl/home/brands/:brandId/media',
    BrandMenu = '/brands/:brandId/menus/:menuId',
    BrandPricingRevaluation = '/brands/:brandId/pricing_revaluation',
    ListCustomers = '/customers',
    CustomerSettings = '/customers/settings',
    CustomerPricingRules = '/customers/pricing_rules',
    CreateCustomer = '/customers/create',
    UpdateCustomer = '/customers/:customerId/update',
    CustomerInfo = '/customers/:customerId/info',
    CustomerInfoV1 = ':backofficeUrl/home/customers/:customerId/student',
    CreateGroup = '/brands/:brandId/guest_groups/groups/create',
    CreateSection = '/brands/:brandId/guest_groups/sections/create',
    CreateSubSection = '/brands/:brandId/guest_groups/sub_sections/create',
    EditGroup = '/brands/:brandId/guest_groups/groups/:groupId',
    EditSection = '/brands/:brandId/guest_groups/sections/:groupId',
    EditSubSection = '/brands/:brandId/guest_groups/sub_sections/:groupId',
    AccessFlags = '/brands/:brandId/users/:userId/access_flags',
    UpdateUserPassword = ':backofficeUrl/home/brands/:brandId/users/:userId/password',
    UpdatePassword = '/password',
    BrandUserInfo = ':backofficeUrl/home/brands/:brandId/users/:userId/info',
    Menus = '/brands/:brandId/restaurants/:restaurantId/menus',
    BrandMenus = '/brands/:brandId/menus',
    BrandCrossSelling = '/brands/:brandId/cross-selling',
    BrandMenuCategoriesV1 = ':backofficeUrl/home/brands/:brandId/menus/:menuId/categories',
    BrandMenuProductsV1 = ':backofficeUrl/home/brands/:brandId/menus/:menuId/products',
    BrandMenuStepsV1 = ':backofficeUrl/home/brands/:brandId/menus/:menuId/steps',
    BrandMenuTagsV1 = ':backofficeUrl/home/brands/:brandId/menus/:menuId/tags',
    RestaurantMenus = '/brands/:brandId/restaurants/:restaurantId/menus',
    RestaurantMenuCategoriesV1 = ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/menus/:menuId/categories',
    RestaurantMenuProductsV1 = ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/menus/:menuId/products',
    RestaurantMenuStepsV1 = ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/menus/:menuId/steps',
    RestaurantMenuTagsV1 = ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/menus/:menuId/tags',
    RestaurantTableLayouts = '/brands/:brandId/restaurants/:restaurantId/channels/pos/table-layouts',
    RestaurantTableLayoutsRooms = '/brands/:brandId/restaurants/:restaurantId/channels/pos/table-layouts/:tableLayoutId/rooms',
    MenuOperations = '/brands/:brandId/restaurants/:restaurantId/menus/operations',
    Report = '/report/:reportName',
    BrandHistory = '/brands/:brandId/history',
    BadgeReader = '/brands/:brandId/badge_reader',
    PosConfiguration = ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/channels/pos/configuration',
    PosDevices = ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/channels/pos/pos_devices',
    PosAccess = '/brands/:brandId/restaurants/:restaurantId/channels/pos/access',
    PosAccessUsers = '/brands/:brandId/restaurants/:restaurantId/channels/pos/access/users',
    CreatePosAccessUser = '/brands/:brandId/restaurants/:restaurantId/channels/pos/access/users/new',
    EditPosAccessUser = '/brands/:brandId/restaurants/:restaurantId/channels/pos/access/users/edit/:userId',
    PosAccessRoles = '/brands/:brandId/restaurants/:restaurantId/channels/pos/access/roles',
    EditPosAccessRole = '/brands/:brandId/restaurants/:restaurantId/channels/pos/access/roles/edit/:roleId',
    PosAccessGroups = '/brands/:brandId/restaurants/:restaurantId/channels/pos/access/groups',
    CreatePosAccessGroup = '/brands/:brandId/restaurants/:restaurantId/channels/pos/access/groups/new',
    EditPosAccessGroup = '/brands/:brandId/restaurants/:restaurantId/channels/pos/access/groups/edit/:groupId',
    PosDeviceInformation = '/brands/:brandId/restaurants/:restaurantId/channels/pos/pos_devices/:posDeviceId/infos',
    PosDeviceConfiguration = '/brands/:brandId/restaurants/:restaurantId/channels/pos/pos_devices/:posDeviceId/configuration',
    PosDeviceAdditionalDevices = '/brands/:brandId/restaurants/:restaurantId/channels/pos/pos_devices/:posDeviceId/devices',
    PosDeviceArchives = '/brands/:brandId/restaurants/:restaurantId/channels/pos/pos_devices/:posDeviceId/archives',
    PosDeviceFeatures = '/brands/:brandId/restaurants/:restaurantId/channels/pos/pos_devices/:posDeviceId/features',
    PosDevicePreferences = '/brands/:brandId/restaurants/:restaurantId/channels/pos/pos_devices/:posDeviceId/preferences',
    PosDeviceCatalogHistory = '/brands/:brandId/restaurants/:restaurantId/channels/pos/pos_devices/:posDeviceId/catalog_history',
    PosDeviceInformationV1 = ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/channels/pos/pos_devices/device_v2/:posDeviceId/infos',
    PosDeviceConfigurationV1 = ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/channels/pos/pos_devices/device_v2/:posDeviceId/configuration',
    PosDeviceAdditionalDevicesV1 = ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/channels/pos/pos_devices/device_v2/:posDeviceId/additional_devices',
    PosDeviceArchivesV1 = ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/channels/pos/pos_devices/device_v2/:posDeviceId/archives',
    PosDeviceFeaturesV1 = ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/channels/pos/pos_devices/device_v2/:posDeviceId/features',
    PosDevicePreferencesV1 = ':backofficeUrl/home/brands/:brandId/restaurants/:restaurantId/channels/pos/pos_devices/device_v2/:posDeviceId/preferences',
}

export enum NavigationParametersEnum {
    brandId = 'brandId',
    restaurantId = 'restaurantId',
    menuId = 'menuId',
    pricingRuleId = 'pricingRuleId',
    entranceFeeId = 'entranceFeeId',
    grantId = 'grantId',
    orderId = 'orderId',
    billingEntityCode = 'billingEntityCode',
    groupId = 'groupId',
    customerId = 'customerId',
    webhookId = 'webhookId',
    transactionId = 'transactionId',
    channelId = 'channelId',
    posRoleId = 'posRoleId',
    userId = 'userId',
    posDeviceId = 'posDeviceId',
}

export type NavigationContext = Partial<Record<NavigationParametersEnum, string>>;

export type ReferencedTabs =
    | 'brands'
    | 'restaurants'
    | 'brandMenus'
    | 'restaurantMenus'
    | 'iopay'
    | 'pos'
    | 'posAccess'
    | 'user'
    | 'posDevice'
    | 'customers';

export type FeatureFlag =
    | 'useOpsBulkTools'
    | 'useGuestHierarchy'
    | 'useNewEditMenuPages'
    | 'useNewPaymentMethodsPage'
    | 'useIopayKycInterface'
    | 'useCrossSelling'
    | 'useBillingSpace'
    | 'useAiMenuScan'
    | 'useNewChannelsPage'
    | 'useNewBrandUserPermissionPage'
    | 'useNewUsersPage'
    | 'useNewBrandsPage'
    | 'useSalesSummaryExportWithTaxExcluded';

export type BreadCrumb = {
    source?:
        | 'name'
        | NavigationParametersEnum.brandId
        | NavigationParametersEnum.restaurantId
        | NavigationParametersEnum.channelId;
    accessor?: string;
};

export type RouteDeclarationBase = {
    name: string;
    parent?: Routes;
    context?: NavigationParametersEnum[];
    tab?: ReferencedTabs;
    tabIndex?: number;
    backToV1?: string;
    breadCrumb?: BreadCrumb;
    enableFeatureFlag?: FeatureFlag;
    disableFeatureFlag?: FeatureFlag;
    roles?: UserRoles[];
    brandOption?: BrandOptionName;
};

export type UriRouteDeclaration = {
    uri: Routes;
};

export type UriRouteResolveDeclaration = {
    resolveUri?: (context: NavigationContext & { backofficeUrl: string }) => string;
};

export type ComponentRouteDeclaration<RoutesDef> = {
    path: RoutesDef;
    exact?: boolean;
    component: React.FunctionComponent<React.PropsWithChildren<any>>;
    accessFlags?: AccessFlags[];
    type?: 'page' | 'modal' | 'portal';
    routeProps?: Omit<Partial<ModalProps>, 'onHide' | 'isOpen'>;
};

export type RouteDeclaration<RoutesDef = Routes> = RouteDeclarationBase &
    Either<UriRouteResolveDeclaration, Either<UriRouteDeclaration, ComponentRouteDeclaration<RoutesDef>>>;

export type RouteDeclarationWithParams = RouteDeclaration & { params: Record<string, string> };

export type RedirectionDeclaration = {
    from: Routes;
    to: Routes;
    exact: boolean;
};
