import { Column } from 'react-table';
import { TableRow } from '@/components/atoms/Table/Table.type';
import { useIntl } from 'react-intl';
import { computeCurrency, computeText } from '@/locales/utils';
import {
    useFindAllModuleStripesByBrandQuery,
    useFindModuleStripesByRestaurantsQuery,
} from '@/services/innovorder/module_stripes/module_stripes.endpoints';
import { useSelector } from 'react-redux';
import { getUserBrandId, getUserRestaurants, getUserRole } from '@/redux/user';
import { UserRestaurant, UserRoles } from '@/services/innovorder/user/user.type';
import { useMemo, useState } from 'react';
import {
    useGetInvoicesQuery,
    useLazyGetInvoiceDownloadLinkQuery,
} from '@/services/innovorder/billing/invoices/invoices.endpoints';
import { InvoiceStatus } from '@/services/innovorder/billing/invoices/invoices.type';
import { assertUnreachable } from '@/utils/typescript';
import { Theme, useTheme } from '@emotion/react';
import { SVGIcons } from '@/components/atoms/Icon/SVGIcon.interface';
import { compareDates } from '@/utils/date';
import { SupportedLanguages } from '@/locales';

export const getInvoiceStatusBackgroundColor = (status: InvoiceStatus, theme: Theme): string => {
    if (status === InvoiceStatus.Paid) return theme.color.io.green100;
    if (status === InvoiceStatus.Due) return theme.color.io.destructive100;
    return assertUnreachable(status);
};

export const getInvoiceStatusTextColor = (status: InvoiceStatus, theme: Theme): string => {
    if (status === InvoiceStatus.Paid) return theme.color.io.green900;
    if (status === InvoiceStatus.Due) return theme.color.io.destructive900;
    return assertUnreachable(status);
};

export type BillingViewModel = {
    invoices: TableRow[];
    show: boolean;
    hasStripeModules: boolean;
    isStripeModuleLoading: boolean;
    onClearAction: () => void;
    toggleEditConfigurationModal: () => void;
};

export function useBillingVM(): BillingViewModel {
    const theme = useTheme();
    const intl = useIntl();
    const brandId = useSelector(getUserBrandId) as number;
    const userRole = useSelector(getUserRole) as UserRoles;
    const userRestaurants = useSelector(getUserRestaurants) as UserRestaurant[];
    const { data: invoices } = useGetInvoicesQuery();
    const [show, doShow] = useState<boolean>(false);
    const creationDateFormatter = Intl.DateTimeFormat(intl.locale, { dateStyle: 'short', timeStyle: 'short' });
    const dueDateFormatter = Intl.DateTimeFormat(intl.locale, { dateStyle: 'short' });
    const [getDownloadLink] = useLazyGetInvoiceDownloadLinkQuery();

    const { data: stripeModules, isLoading: isStripeModuleLoading } =
        userRole === UserRoles.brand
            ? useFindAllModuleStripesByBrandQuery({ brandId })
            : useFindModuleStripesByRestaurantsQuery({
                  restaurantIds: userRestaurants.map((restaurant) => restaurant.restaurantId).join(','),
              });

    const hasStripeModules = !!stripeModules?.length;

    const rows: TableRow[] = useMemo(() => {
        return !invoices
            ? []
            : invoices?.map((invoice) => {
                  return {
                      creationDate: {
                          type: 'string',
                          value: {
                              text: creationDateFormatter.format(new Date(invoice.createdAt)),
                          },
                      },
                      amount: {
                          type: 'string',
                          value: {
                              text: computeCurrency({ intl, amount: invoice.amount, currency: 'EUR' }),
                          },
                      },
                      status: {
                          type: 'badge',
                          value: {
                              text: `billing.invoices.status.${invoice.status}`,
                              backgroundColor: getInvoiceStatusBackgroundColor(invoice.status, theme),
                              textColor: getInvoiceStatusTextColor(invoice.status, theme),
                              weight: 'bold',
                          },
                      },
                      service: {
                          type: 'string',
                          value: {
                              text: `billing.invoices.psp.${invoice.psp}`,
                          },
                      },
                      invoiceNumber: {
                          type: 'string',
                          value: {
                              text: invoice.number,
                          },
                      },
                      invoiceName: {
                          type: 'string',
                          value: {
                              text: `billing.invoices.name.${invoice.psp}`,
                          },
                      },
                      dueDate: {
                          type: 'string',
                          value: {
                              text: dueDateFormatter.format(new Date(invoice.dueDate)),
                          },
                      },
                      downloadPDF: {
                          type: 'texticon',
                          value: {
                              icon: SVGIcons.EXPORTS,
                              onClick: async () => {
                                  const response = await getDownloadLink({ invoiceId: invoice.number }).unwrap();
                                  const link = document.createElement('a');
                                  link.href = response.downloadLink;
                                  link.target = '_blank';
                                  link.click();
                                  link.remove();
                              },
                              text: 'billing.invoices.download',
                          },
                      },
                  };
              });
    }, [invoices, theme, creationDateFormatter, intl, dueDateFormatter, getDownloadLink]);

    return {
        invoices: rows,
        show,
        hasStripeModules,
        isStripeModuleLoading,
        onClearAction: () => doShow(false),
        toggleEditConfigurationModal: () => doShow(true),
    };
}

export const getBillingTableColumns = (): readonly Column<TableRow>[] => {
    const intl = useIntl();
    return [
        {
            Header: computeText(intl, 'billing.table.creationDate'),
            accessor: 'creationDate',
            minWidth: 100,
            disableFilters: true,
            disableSortBy: false,
            sortType: (rowA, rowB, id) => {
                return compareDates(
                    rowA.values[id].value.text,
                    rowB.values[id].value.text,
                    intl.locale === SupportedLanguages.en ? 'MM/DD/YYYY HH:mm' : 'DD/MM/YYYY HH:mm',
                );
            },
        },
        {
            Header: computeText(intl, 'billing.table.amount'),
            accessor: 'amount',
            minWidth: 100,
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: computeText(intl, 'billing.table.status'),
            accessor: 'status',
            minWidth: 100,
            disableFilters: true,
            disableSortBy: false,
        },
        {
            Header: computeText(intl, 'billing.table.service'),
            accessor: 'service',
            minWidth: 100,
            disableFilters: true,
            disableSortBy: false,
        },
        {
            Header: computeText(intl, 'billing.table.invoiceNumber'),
            accessor: 'invoiceNumber',
            minWidth: 100,
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: computeText(intl, 'billing.table.invoiceName'),
            accessor: 'invoiceName',
            minWidth: 100,
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: computeText(intl, 'billing.table.dueDate'),
            accessor: 'dueDate',
            minWidth: 100,
            disableFilters: true,
            disableSortBy: false,
            sortType: (rowA, rowB, id) => {
                return compareDates(
                    rowA.values[id].value.text,
                    rowB.values[id].value.text,
                    intl.locale === SupportedLanguages.en ? 'MM/DD/YYYY' : 'DD/MM/YYYY',
                );
            },
        },
        {
            Header: computeText(intl, 'billing.table.downloadPDF'),
            accessor: 'downloadPDF',
            minWidth: 100,
            disableFilters: true,
            disableSortBy: true,
        },
    ];
};
