import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Modal from '@/components/atoms/Modal';
import { Button } from '@/components/atoms/Button';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useForm, UseFormReturn } from 'react-hook-form';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCreateCustomerMutation } from '@/services/innovorder/customer/customer.endpoints';
import { getUseCustomerFormAutoGenerateEmailAndPassword } from '@/redux/launchDarkly';
import { getAssertedUserBrandId, getUserToken } from '@/redux/user';
import CreateCustomerForm from './CreateCustomerForm/CreateCustomerForm';
import { StyledModalContainer, StyledClientCreationForm } from './CreateCustomerModal.styles';
import {
    prepareCreateCustomerPayload,
    generateCreateCustomerFormDefaultValuesWithEmailAndPassword,
    generateCreateCustomerFormDefaultValues,
} from './CreateCustomerModal.utils';
import {
    getFormErrorInfo,
    redirectToCustomerDetailsPage,
    UNKNOWN_ERROR_CODE,
} from '@/utils/factories/customer/customer.utils';
import { BaseCustomerAttributes } from './CreateCustomerModal.types';

interface CreateCustomerModalProps {
    title: string;
    isOpen: boolean;
    onHide: () => void;
}

const CreateCustomerModal: React.FunctionComponent<React.PropsWithChildren<CreateCustomerModalProps>> = ({
    title,
    isOpen,
    onHide,
}) => {
    const theme = useTheme();
    const brandId = useSelector(getAssertedUserBrandId);
    const token = useSelector(getUserToken);
    const customerFormAutoGenerateEmailAndPassword = useSelector(getUseCustomerFormAutoGenerateEmailAndPassword);
    const [createCustomerMutation, { isSuccess, isLoading, data, error }] = useCreateCustomerMutation();
    const intl = useIntl();

    if (isSuccess && data?.customerId) {
        redirectToCustomerDetailsPage(data.customerId);
    }

    const customerForm: UseFormReturn<BaseCustomerAttributes> = useForm<BaseCustomerAttributes>({
        defaultValues: customerFormAutoGenerateEmailAndPassword
            ? generateCreateCustomerFormDefaultValuesWithEmailAndPassword()
            : generateCreateCustomerFormDefaultValues(),
        mode: 'onBlur',
    });

    const createCustomer = () => {
        createCustomerMutation({
            ...prepareCreateCustomerPayload(customerForm.getValues(), brandId),
            token,
        });
    };

    useEffect(() => {
        if (error) {
            const formError = getFormErrorInfo(error as FetchBaseQueryError, intl);
            const validErrorCode = formError.name !== UNKNOWN_ERROR_CODE;
            if (validErrorCode) {
                customerForm.setError(
                    formError.name as keyof BaseCustomerAttributes,
                    { message: formError.message },
                    { shouldFocus: true },
                );
            }
        }
    }, [customerForm, error, intl]);
    const nextButton = (
        <Button
            onClick={createCustomer}
            buttonType="primary"
            disabled={!customerForm.formState.isValid}
            isLoading={isLoading}
        >
            <FormattedMessage id="button.next" />
        </Button>
    );

    return (
        <Modal
            isOpen={isOpen}
            onHide={onHide}
            title={title}
            iconColor={theme.color.bloodyMary}
            buttons={nextButton}
            shouldNotHideOnClickOutside
        >
            <StyledModalContainer>
                <StyledClientCreationForm>
                    <FormattedMessage id="customer.form.modalDescription" />
                    <CreateCustomerForm brandId={brandId} customerForm={customerForm} />
                </StyledClientCreationForm>
            </StyledModalContainer>
        </Modal>
    );
};

export default CreateCustomerModal;
