import { StepData } from '@/components/atoms/Wizard/Wizard';
import { CreateBrandPayload, Currency } from '@/services/innovorder/brand/brand.type';
import { useCreateBrandMutation } from '@/services/innovorder/brand/brand.endpoints';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState, useEffect, useCallback } from 'react';
import { useForm, UseFormReturn, useWatch, useFormState } from 'react-hook-form';
import * as Yup from 'yup';
import { computeText } from '@/locales/utils';
import { useIntl } from 'react-intl';
import { redirectToBackofficeV1 } from '@/utils/backoffice-v1';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { isValidPhoneNumber } from 'libphonenumber-js';
import ManageCanalsStep from './ManageCanalsStep/ManageCanalsStep';
import CreateUserStep from './CreateUserStep/CreateUserStep';
import BrandNameStep from './BrandNameStep/BrandNameStep';

type CreateBrandViewModel = {
    steps: StepData[];
    form: UseFormReturn<CreateBrandPayload, any, undefined>;
    onSubmit: (data: CreateBrandPayload) => void;
    isLoading: boolean;
    selectedStep: number | null;
};

const useCreateBrandViewModel = (): CreateBrandViewModel => {
    const intl = useIntl();

    const [selectedStep, setSelectedStep] = useState<number | null>(null);
    const [steps, setSteps] = useState<StepData[]>([
        {
            component: <BrandNameStep />,
            title: computeText(intl, 'brands.form.brandInfo.title'),
        },
        {
            component: <CreateUserStep />,
            title: computeText(intl, 'brands.form.selectUser.title'),
        },
        {
            component: <ManageCanalsStep />,
            title: computeText(intl, 'brands.form.canals.title'),
        },
    ]);

    const [createBrand, { data: newBrandData, isSuccess, isLoading, error }] = useCreateBrandMutation();

    const resolver = yupResolver(
        Yup.object({
            name: Yup.string().min(2, computeText(intl, 'field.minTwo')).required(computeText(intl, 'field.required')),
            firstName: Yup.string().required(computeText(intl, 'field.required')),
            lastName: Yup.string().required(computeText(intl, 'field.required')),
            phone: Yup.string()
                .test(
                    'validPhoneNumber',
                    computeText(intl, 'brands.form.error.phone.valid'),
                    (value) => !!value && isValidPhoneNumber(value, 'FR'),
                )
                .required(computeText(intl, 'field.required')),
            email: Yup.string()
                .email(computeText(intl, 'field.invalidEmail'))
                .required(computeText(intl, 'field.required')),
            password: Yup.string()
                .min(12, computeText(intl, 'brands.form.error.password.min'))
                .required(computeText(intl, 'field.required')),
            confirmPassword: Yup.string()
                .oneOf(
                    [Yup.ref('password'), null],
                    computeText(intl, 'brands.form.error.passwordConfirm.sameAsPassword'),
                )
                .required(computeText(intl, 'field.required')),
        }),
    );

    const form: UseFormReturn<CreateBrandPayload> = useForm<CreateBrandPayload>({
        resolver,
        mode: 'all',
        defaultValues: {
            name: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: '',
            email: '',
            phone: '',
            locale: 'fr',
            channels: [],
            currency: Currency.EUR,
        },
    });

    const formValues = useWatch({ control: form.control });
    const { errors } = useFormState({ control: form.control });

    const onSubmit = useCallback(
        (data: CreateBrandPayload) => {
            createBrand(data);
        },
        [createBrand],
    );

    useEffect(() => {
        const errorObject = error && ((error as FetchBaseQueryError).data as { code: string; message: string });
        if (errorObject?.code === 'duplicate_email' || errorObject?.code === 'invalid_parameters') {
            form.setError('email', { message: errorObject.message });
            setSelectedStep(1);
        }
        if (errorObject?.code === 'invalid_password') {
            form.setError('password', { message: errorObject.message });
            setSelectedStep(1);
        }
    }, [error]);

    useEffect(() => {
        if (selectedStep != null) {
            setSelectedStep(null);
        }
    }, [selectedStep]);

    useEffect(() => {
        if (isSuccess && newBrandData?.brandId) {
            redirectToBackofficeV1(`/home/brands/${newBrandData?.brandId}/info`);
        }
    }, [isSuccess, newBrandData]);

    useEffect(() => {
        setSteps((prevSteps) => {
            const canGoToCanalsStep =
                !formValues.firstName ||
                !formValues.lastName ||
                !formValues.phone ||
                !formValues.email ||
                !formValues.password ||
                !formValues.confirmPassword ||
                !!errors.firstName ||
                !!errors.lastName ||
                !!errors.phone ||
                !!errors.email ||
                !!errors.password ||
                !!errors.confirmPassword;

            const newSteps = [...prevSteps];
            newSteps[0].isNextDisabled = !formValues.name || !!errors.name;
            newSteps[1].isNextDisabled = canGoToCanalsStep;
            return newSteps;
        });
    }, [formValues, errors]);
    return { steps, form, onSubmit, isLoading, selectedStep };
};

export default useCreateBrandViewModel;
