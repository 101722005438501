import { PaymentMethodCode } from '@/services/innovorder/paymentMethod/paymentMethod.type';
import { ChannelId } from '@/services/innovorder/channel/channel.type';

export enum ParsedOptions {
    CHANGE_ALLOWED = 'changeAllowed',
    CREDIT_NOTE_ALLOWED = 'creditNoteAllowed',
}

type codeDefaultListType = {
    [key in PaymentMethodCode]: {
        code: PaymentMethodCode;
        label: string;
        channels: ChannelId[];
        parsedOptions: ParsedOptions[];
        isValid: () => boolean;
    };
};

export const codeDefaultList: codeDefaultListType = {
    [PaymentMethodCode.Unknown]: {
        code: PaymentMethodCode.Unknown,
        label: 'Autre',
        channels: [ChannelId.POS],
        parsedOptions: [ParsedOptions.CHANGE_ALLOWED],
        isValid: () => {
            return true;
        },
    },
    [PaymentMethodCode.Cash]: {
        code: PaymentMethodCode.Cash,
        label: 'Espèce',
        channels: [ChannelId.KIOSK, ChannelId.WEB, ChannelId.POS],
        parsedOptions: [ParsedOptions.CHANGE_ALLOWED],
        isValid: () => {
            return true;
        },
    },
    [PaymentMethodCode.CreditCard]: {
        code: PaymentMethodCode.CreditCard,
        label: 'CB',
        channels: [ChannelId.KIOSK, ChannelId.WEB, ChannelId.POS],
        parsedOptions: [ParsedOptions.CHANGE_ALLOWED],
        isValid: () => {
            return true;
        },
    },
    [PaymentMethodCode.CreditNote]: {
        code: PaymentMethodCode.CreditNote,
        label: 'Avoir',
        channels: [ChannelId.POS],
        parsedOptions: [ParsedOptions.CHANGE_ALLOWED],
        isValid: () => {
            return true;
        },
    },
    [PaymentMethodCode.LuncheonVoucher]: {
        code: PaymentMethodCode.LuncheonVoucher,
        label: 'Ticket resto',
        channels: [ChannelId.KIOSK, ChannelId.WEB, ChannelId.POS],
        parsedOptions: [ParsedOptions.CREDIT_NOTE_ALLOWED],
        isValid: () => {
            return true;
        },
    },
    [PaymentMethodCode.Check]: {
        code: PaymentMethodCode.Check,
        label: 'Chèque',
        channels: [ChannelId.KIOSK, ChannelId.WEB, ChannelId.POS],
        parsedOptions: [ParsedOptions.CHANGE_ALLOWED],
        isValid: () => {
            return true;
        },
    },
    [PaymentMethodCode.AlloResto]: {
        code: PaymentMethodCode.AlloResto,
        label: 'Just Eat',
        channels: [ChannelId.POS],
        parsedOptions: [ParsedOptions.CHANGE_ALLOWED],
        isValid: () => {
            return true;
        },
    },
    [PaymentMethodCode.Transfer]: {
        code: PaymentMethodCode.Transfer,
        label: 'Virement',
        channels: [ChannelId.KIOSK, ChannelId.WEB, ChannelId.POS],
        parsedOptions: [ParsedOptions.CHANGE_ALLOWED],
        isValid: () => {
            return true;
        },
    },
    [PaymentMethodCode.AmericanExpress]: {
        code: PaymentMethodCode.AmericanExpress,
        label: 'American Express',
        channels: [ChannelId.KIOSK, ChannelId.WEB, ChannelId.POS],
        parsedOptions: [ParsedOptions.CHANGE_ALLOWED],
        isValid: () => {
            return true;
        },
    },
    [PaymentMethodCode.RestoFlash]: {
        code: PaymentMethodCode.RestoFlash,
        label: 'Resto Flash',
        channels: [ChannelId.POS],
        parsedOptions: [ParsedOptions.CREDIT_NOTE_ALLOWED],
        isValid: () => {
            return true;
        },
    },
    [PaymentMethodCode.EWallet]: {
        code: PaymentMethodCode.EWallet,
        label: 'EWallet',
        channels: [ChannelId.POS],
        parsedOptions: [ParsedOptions.CREDIT_NOTE_ALLOWED],
        isValid: () => {
            // brand.options?.EDUCATION_MODE.isActivated && brand.moduleStripeId;
            return true;
        },
    },
    [PaymentMethodCode.ELuncheonVoucher]: {
        code: PaymentMethodCode.ELuncheonVoucher,
        label: 'E Ticket Resto',
        channels: [ChannelId.KIOSK, ChannelId.WEB, ChannelId.POS],
        parsedOptions: [ParsedOptions.CHANGE_ALLOWED],
        isValid: () => {
            return true;
        },
    },
    [PaymentMethodCode.Unknown2]: {
        code: PaymentMethodCode.Unknown2,
        label: 'Autre 2',
        channels: [ChannelId.POS],
        parsedOptions: [ParsedOptions.CHANGE_ALLOWED],
        isValid: () => {
            return true;
        },
    },
    [PaymentMethodCode.UberEats]: {
        code: PaymentMethodCode.UberEats,
        label: 'Uber Eats',
        channels: [ChannelId.POS],
        parsedOptions: [ParsedOptions.CHANGE_ALLOWED],
        isValid: () => {
            return true;
        },
    },
    [PaymentMethodCode.Deliveroo]: {
        code: PaymentMethodCode.Deliveroo,
        label: 'Deliveroo',
        channels: [ChannelId.POS],
        parsedOptions: [ParsedOptions.CHANGE_ALLOWED],
        isValid: () => {
            return true;
        },
    },
    [PaymentMethodCode.Foodora]: {
        code: PaymentMethodCode.Foodora,
        label: 'Foodora',
        channels: [ChannelId.POS],
        parsedOptions: [ParsedOptions.CHANGE_ALLOWED],
        isValid: () => {
            return true;
        },
    },
    [PaymentMethodCode.Lydia]: {
        code: PaymentMethodCode.Lydia,
        label: 'Lydia',
        channels: [ChannelId.KIOSK, ChannelId.WEB],
        parsedOptions: [],
        isValid: () => {
            return true;
        },
    },
    [PaymentMethodCode.ExternalPayment]: {
        code: PaymentMethodCode.ExternalPayment,
        label: 'ExternalPayment',
        channels: [ChannelId.WEB],
        parsedOptions: [],
        isValid: () => {
            return true;
        },
    },
    [PaymentMethodCode.CreditNoteIssued]: {
        code: PaymentMethodCode.CreditNoteIssued,
        label: '',
        channels: [],
        parsedOptions: [],
        isValid: () => {
            return false;
        },
    },
    [PaymentMethodCode.Change]: {
        code: PaymentMethodCode.Change,
        label: '',
        channels: [],
        parsedOptions: [],
        isValid: () => {
            return false;
        },
    },
    [PaymentMethodCode.Ingenico]: {
        code: PaymentMethodCode.Ingenico,
        label: '',
        channels: [],
        parsedOptions: [],
        isValid: () => {
            return false;
        },
    },
    [PaymentMethodCode.Nepting]: {
        code: PaymentMethodCode.Nepting,
        label: '',
        channels: [],
        parsedOptions: [],
        isValid: () => {
            return false;
        },
    },
    [PaymentMethodCode.Yavin]: {
        code: PaymentMethodCode.Yavin,
        label: '',
        channels: [],
        parsedOptions: [],
        isValid: () => {
            return false;
        },
    },
    [PaymentMethodCode.Paygreen]: {
        code: PaymentMethodCode.Paygreen,
        label: '',
        channels: [],
        parsedOptions: [],
        isValid: () => {
            return false;
        },
    },
    [PaymentMethodCode.Stripe]: {
        code: PaymentMethodCode.Stripe,
        label: '',
        channels: [],
        parsedOptions: [],
        isValid: () => {
            return false;
        },
    },
    [PaymentMethodCode.IOPay]: {
        code: PaymentMethodCode.IOPay,
        label: 'Adyen',
        channels: [],
        parsedOptions: [],
        isValid: () => {
            return false;
        },
    },
    [PaymentMethodCode.Edenred]: {
        code: PaymentMethodCode.Edenred,
        label: 'Edenred',
        channels: [],
        parsedOptions: [ParsedOptions.CHANGE_ALLOWED],
        isValid: () => {
            return true;
        },
    },
    [PaymentMethodCode.Izly]: {
        code: PaymentMethodCode.Izly,
        label: 'Izly',
        channels: [],
        parsedOptions: [ParsedOptions.CHANGE_ALLOWED],
        isValid: () => {
            return false;
        },
    },
};
