import { computeText } from '@/locales/utils';
import { useLazyGetBrandOptInsQuery } from '@/services/innovorder/brand/brand.endpoints';
import { BrandOptIns } from '@/services/innovorder/brand/brand.type';
import { useLazyGetCustomerListForExportQuery } from '@/services/innovorder/customer/customer.endpoints';
import { CustomerForExport, CustomerFetchOptions } from '@/services/innovorder/customer/customer.types';
import {
    BaseGuestGroupAttributes,
    GuestGroupDepth,
    GuestGroupMapByDepth,
} from '@/services/innovorder/guestGroup/guestGroup.type';
import { downloadFile } from '@/utils/file/utils';
import { XlsxFile } from '@/utils/xslx';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

type UseExportGroupsStudentsProps = {
    guestGroupMapByDepth?: GuestGroupMapByDepth;
};

export type ExportGuestGroup = Pick<BaseGuestGroupAttributes, 'name' | 'depth' | 'parentGuestGroupId'>;

export const useExportGroupsStudents = ({
    guestGroupMapByDepth,
}: UseExportGroupsStudentsProps): { onExport: (guestGroup: ExportGuestGroup) => Promise<void>; isLoading: boolean } => {
    const intl = useIntl();
    const { brandId } = useParams<{ brandId: string }>();
    const [isLoading, setIsLoading] = useState(false);

    const [getCustomerList] = useLazyGetCustomerListForExportQuery();
    const [getBrandOptIns] = useLazyGetBrandOptInsQuery();

    const customerFetchOptions = useCallback(
        (guestGroup: ExportGuestGroup) => {
            const baseOptions: CustomerFetchOptions = {
                getArchivedCustomers: false,
                getDisabledCustomers: false,
                emailDomainNames: null,
                groups: null,
                sections: null,
                subsections: null,
            };
            if (!guestGroup || !guestGroupMapByDepth) return baseOptions;
            if (guestGroup.depth === GuestGroupDepth.GROUPS) {
                return {
                    ...baseOptions,
                    groups: guestGroup.name,
                };
            }
            if (guestGroup.depth === GuestGroupDepth.SECTIONS) {
                return {
                    ...baseOptions,
                    groups: guestGroupMapByDepth[GuestGroupDepth.GROUPS][guestGroup.parentGuestGroupId as number].name,
                    sections: guestGroup.name,
                };
            }

            const parentSection =
                guestGroupMapByDepth[GuestGroupDepth.SECTIONS][guestGroup.parentGuestGroupId as number];
            const parentGroup =
                guestGroupMapByDepth[GuestGroupDepth.GROUPS][parentSection.parentGuestGroupId as number];
            return {
                ...baseOptions,
                groups: parentGroup.name,
                sections: parentSection.name,
                subsections: guestGroup.name,
            };
        },
        [guestGroupMapByDepth],
    );

    const setHeaders = useCallback(
        (brandOptIns: BrandOptIns) => {
            const headers = [
                computeText(intl, 'groups.export.student.customerId'),
                computeText(intl, 'groups.export.student.email'),
                computeText(intl, 'groups.export.student.password'),
                computeText(intl, 'groups.export.student.firstName'),
                computeText(intl, 'groups.export.student.lastName'),
                computeText(intl, 'groups.export.student.phone'),
                computeText(intl, 'groups.export.student.pinCode'),
                computeText(intl, 'groups.export.student.badgeNumber'),
                computeText(intl, 'groups.export.student.studentNumber'),
                computeText(intl, 'groups.export.student.group'),
                computeText(intl, 'groups.export.student.section'),
                computeText(intl, 'groups.export.student.subSection'),
                computeText(intl, 'groups.export.student.accountType'),
                computeText(intl, 'groups.export.student.tariffCode'),
                computeText(intl, 'groups.export.student.grantCode'),
                computeText(intl, 'groups.export.student.entranceFeeCode'),
                computeText(intl, 'groups.export.student.pricingRuleCode'),
                computeText(intl, 'groups.export.student.ewalletOverdraftAmount'),
                computeText(intl, 'groups.export.student.exitDate'),
                computeText(intl, 'groups.export.student.accessFrom'),
                computeText(intl, 'groups.export.student.accessUntil'),
            ];

            if (brandOptIns) {
                for (let i = 0; i < brandOptIns.length; i++) {
                    headers.push(brandOptIns[i].text);
                }
            }

            return headers;
        },
        [intl],
    );

    const setSheetOptions = useCallback(
        (brandOptIns: BrandOptIns) => {
            const sheetOptions = {
                customers: {
                    name: 'Students',
                    headers: setHeaders(brandOptIns),
                    mapper: (customer: CustomerForExport) => {
                        const row = [
                            customer.customerId,
                            customer.email,
                            '',
                            customer.firstName,
                            customer.lastName,
                            customer.phone,
                            customer.pinCode,
                            customer.badgeNumber,
                            customer.studentNumber,
                            customer.class,
                            customer.section,
                            customer.subSection,
                            customer.accountPaymentType,
                            customer.tariffCode,
                            customer.grantCode,
                            customer.entranceFeeCode,
                            customer.pricingRuleCode,
                            customer.ewalletOverdraftAmount,
                            customer.archivedAt,
                            customer.accessFrom,
                            customer.accessUntil,
                        ];
                        if (brandOptIns) {
                            for (let i = 0; i < brandOptIns.length; i++) {
                                if (customer.optIns && customer.optIns[brandOptIns[i].optInId]) {
                                    row.push(1);
                                } else {
                                    row.push(0);
                                }
                            }
                        }
                        return row;
                    },
                },
            };

            return sheetOptions;
        },
        [setHeaders],
    );

    const onExport = useCallback(
        async (guestGroup: ExportGuestGroup) => {
            setIsLoading(true);
            const customers = await getCustomerList({
                brandId: parseInt(brandId, 10),
                ...customerFetchOptions(guestGroup),
            }).unwrap();
            const brandOptIns = await getBrandOptIns({ brandId: parseInt(brandId, 10) })
                .unwrap()
                .catch(() => []);

            const sheetOptions = setSheetOptions(brandOptIns);

            const sheets = {
                [sheetOptions.customers.name]: [
                    sheetOptions.customers.headers,
                    ...customers.map(sheetOptions.customers.mapper),
                ],
            };
            const xslx = new XlsxFile();
            Object.keys(sheets).forEach((key) => {
                xslx.addWorksheet(key, sheets[key] as (string | number)[][]);
            });

            const dataBuffer = xslx.toArrayBuffer();
            const filename = `export_${new Date().toISOString()}.xlsx`;
            downloadFile(filename, dataBuffer, 'text/csv');
            setIsLoading(false);
        },
        [getCustomerList, getBrandOptIns, customerFetchOptions, brandId, setSheetOptions],
    );

    return { onExport, isLoading };
};
