import { Text } from '@/components/atoms/Text';
import { Title } from '@/components/atoms/Title';
import styled from '@emotion/styled';
import { SvyCallout } from '@/components/savory/SvyCallout';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[4]};

    background: ${({ theme }) => theme.color.white100};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};
`;

export const HeaderContainer = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[2]};
    ${({ theme }) => theme.breakpoints.mobile} {
        flex-direction: column;
    }
`;

export const TitleContainer = styled.div`
    flex: 1;
`;

export const StyledTitle = styled(Title)`
    ${({ theme }) => theme.font.caption.bold};
    font-size: ${({ theme }) => theme.font.size.normal};
    margin-bottom: ${({ theme }) => theme.spacing[1]};
`;

export const Subtitle = styled(Text)`
    ${({ theme }) => theme.font.caption.bold};
    font-size: ${({ theme }) => theme.font.size.normal};
`;

export const ChipsContainer = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[2]};
    flex-wrap: wrap;
    justify-content: right;
    ${({ theme }) => theme.breakpoints.mobile} {
        justify-content: space-between;
        max-width: 100%;
    }
`;

export const Chips = styled.div`
    display: flex;
    background: ${({ theme }) => theme.color.grey100};
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    justify-content: center;
    align-items: center;
    padding: ${({ theme }) => theme.spacing[2]};
    gap: ${({ theme }) => theme.spacing[1]};
    color: ${({ theme }) => theme.color.black200};
`;

export const ChipsValue = styled.span`
    font-size: ${({ theme }) => theme.font.size.normal};
    font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const KpiContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing[2]};
`;

export const StyledCallout = styled(SvyCallout)`
    margin-bottom: ${({ theme }) => theme.spacing[3]};
`;
