import { useEffect, useMemo } from 'react';
import { getUserToken } from '@/redux/user';
import { useSelector } from 'react-redux';
import { useLazyGetPricingRulesQuery } from '@/services/innovorder/pricingRule/pricingRule.endpoints';
import { useFormContext } from 'react-hook-form';
import { getPricingRulesOptions } from '@/utils/factories/hierarchization/hierarchization.utils';

type PricingRuleFormProps = {
    brandId: number;
};

export const usePricingRuleFieldOptions = ({ brandId }: PricingRuleFormProps) => {
    const { setValue, watch } = useFormContext();
    const token = useSelector(getUserToken);

    // Fetch pricing rules
    const [getPricingRules, { data: pricingRulesData, isFetching: isFetchingPricingRules }] =
        useLazyGetPricingRulesQuery();

    // Memoize pricing rules for performance
    const pricingRules = useMemo(() => getPricingRulesOptions(pricingRulesData || []), [pricingRulesData]);

    // Watch the selected group, section, and sub-section
    const watchGroupId = watch('groupId');
    const watchSectionId = watch('sectionId');
    const watchSubSectionId = watch('subSectionId');
    const watchPricingRuleId = watch('pricingRuleId');

    // Fetch pricing rules based on the selected group, section, and sub-section
    useEffect(() => {
        if (brandId && (watchGroupId || watchSectionId || watchSubSectionId)) {
            getPricingRules({ brandId, token, guestGroupId: watchSubSectionId || watchSectionId || watchGroupId });
        }
    }, [brandId, watchGroupId, watchSectionId, watchSubSectionId]);

    // Clear pricing rule if it doesn't exist in the pricing rules list
    useEffect(() => {
        if (!isFetchingPricingRules) {
            const exist = pricingRules.some((option) => option.value === watchPricingRuleId);
            if (!exist) {
                setValue('pricingRuleId', pricingRules.length === 1 ? pricingRules[0].value : null);
            }
        }
    }, [JSON.stringify(pricingRules), isFetchingPricingRules, watchPricingRuleId]);

    return {
        pricingRules,
        isFetchingPricingRules,
    };
};
