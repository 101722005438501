import { TableRow } from '@/components/atoms/Table/Table.type';
import { SupportedLanguages } from '@/locales';
import { SortingRule } from 'react-table';

export type GetBrandPayload = {
    brandId: number;
};

export enum BrandType {
    CommercialCatering = 'COMMERCIAL_CATERING',
    MassCatering = 'MASS_CATERING',
}

export enum BrandOptionName {
    ADVANCED_SECURITY_RULES = 'ADVANCED_SECURITY_RULES',
    AUTO_BILLING = 'AUTO_BILLING',
    BIRTHDATE = 'BIRTHDATE',
    CHARLEMAGNE_EXPORT = 'CHARLEMAGNE_EXPORT',
    EDUCATION_MODE = 'EDUCATION_MODE',
    EXTERNAL_IDS_MAPPING = 'EXTERNAL_IDS_MAPPING',
    EWALLET_AUTO_RELOAD = 'EWALLET_AUTO_RELOAD',
    FOOD_COURT_MODE = 'FOOD_COURT_MODE',
    GRANTS_AND_ENTRANCE_FEE = 'GRANTS_AND_ENTRANCE_FEE',
    HANDLE_BADGE_4_CHAR = 'HANDLE_BADGE_4_CHAR',
    LINK_PRODUCT_IMAGES_BY_SKU = 'LINK_PRODUCT_IMAGES_BY_SKU',
    OPT_IN = 'OPT_IN',
    PIN_CODE_DOUBLE_ATHENTICATION = 'PIN_CODE_DOUBLE_ATHENTICATION',
    PRODUCTION_DECLARATION = 'PRODUCTION_DECLARATION',
    TRIP_ADVISOR_USERNAME = 'TRIP_ADVISOR_USERNAME',
    SHUFFLE_RESTAURANTS = 'SHUFFLE_RESTAURANTS',
    ENABLE_SODEXO_DICTIONARY = 'ENABLE_SODEXO_DICTIONARY',
    ENABLE_ORDER_DETAIL_V2 = 'ENABLE_ORDER_DETAIL_V2',
}

export type BrandOption = {
    optionId: number;
    nameValue: BrandOptionName;
    isActivated: boolean;
    brandId: number | null;
    restaurantId: number | null;

    created_at: Date;
    updated_at: Date | null;
    deleted_at: Date | null;
};

export type BrandOptions = {
    [key in BrandOptionName]?: BrandOption;
};

export enum BrandStatus {
    CHURN = 'CHURN',
    ACTIVE = 'ACTIVE',
    INTERNAL = 'INTERNAL', // Test, Product Academy, Demo...
    SEASONAL_CHURN = 'SEASONAL_CHURN',
    TEST = 'TEST',
}

export type Brand = {
    brandId: number;
    name: string | null;
    locale: SupportedLanguages;
    contactPhone: string | null;
    contactEmail: string | null;
    websiteUrl: string | null;
    websiteCustomCssUrl: string | null;
    is_enabled: boolean;
    legalId: number | null;
    socialNetworkId: number | null;
    moduleStripeId: number | null;
    isEmailWhitelistingEnabled: boolean;
    externalId: string | null;
    options?: BrandOptions;
    lowestPosVersion: string;

    created_at: Date;
    updated_at: Date;
    brandType: BrandType;
    note: string | null;
    status: BrandStatus;
    isFavorite: boolean;
};

export type GetBrandGroups = {
    brandId: number | null;
};

export type ActiveStudentEntities = {
    classes: string[];
    entities: StudentEntity[];
};

export type DomainNames = {
    domainNames: DomainName[];
};

export type DomainName = {
    emailDomainName: string;
};

export type StudentEntity = {
    name: string | null;
    children: StudentEntity[];
};

export type PaymentConfiguration = {
    brandId: number;
    ewalletOverdraftAmount: number;
    paymentConfigurationId: number;
    paymentDailyLimit: number | null;
    useEwalletOverdraft: boolean;
};

export type GetBrandOptionsResponse = BrandOptions;
export type GetBrandsListResponse = { brandsTotal: number; brands: (Brand & { restaurantsCount: number })[] };

export type UpdateBrandOptionsPayload = Pick<Brand, 'brandId' | 'options'>;

export type GetBrandsFilters = Record<string, string>;

export type GetBrandListV2Payload = {
    offset: number;
    limit: number;
    search?: string;
    filter?: GetBrandsFilters;
    sortBy?: SortingRule<TableRow>[];
};
export type ToggleBrandFavoritePayload = {
    brandId: number;
    status: boolean;
    latestFetchParams: GetBrandListV2Payload | null;
};

export enum Currency {
    EUR = 'EUR',
    GPB = 'GPB',
    USD = 'USD',
}

export type CreateBrandPayload = {
    name: string;
    currency: Currency;
    locale: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
    confirmPassword: string;
    channels: number[];
};

export type CreateBrandResponse = {
    brandId: number;
};

export type BrandOptIn = {
    optInId: number;
    text: string;
    url: string;
    brandId: number;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
};

export type BrandOptIns = BrandOptIn[];
