import { GuestGroupMapByDepth, GuestGroupWithGuestsCount } from '@/services/innovorder/guestGroup/guestGroup.type';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { downloadFile } from '@/utils/file/utils';
import { XlsxFile } from '@/utils/xslx';
import { useIntl } from 'react-intl';
import { TableCell, TableRow } from '@/components/atoms/Table/Table.type';
import { Routes } from '@/core/router/routes.types';
import { getUserToken } from '@/redux/user';
import {
    useGetGuestGroupsQuery,
    useDeleteGuestGroupMutation,
} from '@/services/innovorder/guestGroup/guestGroup.endpoints';
import { groupBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory, useParams, generatePath } from 'react-router';
import { Cell } from 'react-table';
import { getGuestGroupMapByDepth } from '@/services/innovorder/guestGroup/utils';
import { getGroupsTableRowsVM, getGroupsTableColumnsVM } from './utils';
import { GroupsTableColumnsByDepth, GroupsTableRowsByDepth, GuestGroupsByDepthKey } from './types';
import { getSheetOptions, formatGuestGroupsForExports } from './UploadModal/utils';

export const useGroupMassImport = ({ guestGroups: data }: { guestGroups: GuestGroupWithGuestsCount[] | undefined }) => {
    const intl = useIntl();
    const guestGroups = useMemo(() => data || [], [data]);
    const [isOpen, setIsOpen] = useState(false);
    const handleHide = () => setIsOpen(false);
    const handleUnHide = () => setIsOpen(true);

    const handleDownload = async () => {
        const groupData = formatGuestGroupsForExports(guestGroups);
        const sheetOptions = getSheetOptions({ intl });
        const sheets = {
            [sheetOptions.group.name]: [sheetOptions.group.headers, ...groupData.map(sheetOptions.group.mapper)],
        };

        const xslx = new XlsxFile();
        Object.keys(sheets).forEach((key) => {
            const sheetData = sheets[key].map((row) => row.map((cell) => cell ?? ''));
            xslx.addWorksheet(key, sheetData);
        });

        const data = xslx.toArrayBuffer();
        const filename = `export_${new Date().toISOString()}.xlsx`;
        downloadFile(filename, data, 'text/csv');
    };

    return {
        isOpen,
        handleHide,
        handleUnHide,
        handleDownload,
    };
};

export const useGroupsViewModel = (): {
    brandId: string;
    groupToDelete: GuestGroupWithGuestsCount | null;
    guestGroups: GuestGroupWithGuestsCount[] | undefined;
    guestGroupMapByDepth: GuestGroupMapByDepth;
    isLoading: boolean;
    isLoadingDelete: boolean;
    groupsTableRowsByDepth: GroupsTableRowsByDepth;
    groupsTableColumnsByDepth: GroupsTableColumnsByDepth;
    handleCloseModal: () => void;
    handleDeleteGroup: () => void;
    handleAddGroup: (depth: GuestGroupsByDepthKey) => void;
    handleOnRowClick: (cell: Cell<TableRow, TableCell<unknown>> | undefined) => void;
} => {
    const intl = useIntl();
    const history = useHistory();
    const { brandId } = useParams<{ brandId: string }>();
    const token = useSelector(getUserToken);
    const [groupToDelete, setGroupToDelete] = useState<GuestGroupWithGuestsCount | null>(null);

    const { data: guestGroups, isLoading } = useGetGuestGroupsQuery(
        {
            brandId: parseInt(brandId, 10),
            token,
        },
        { refetchOnMountOrArgChange: true },
    );
    const guestGroupMapByDepth = useMemo(() => getGuestGroupMapByDepth(guestGroups || []), [guestGroups]);

    const [deleteGuestGroup, { isSuccess, isLoading: isLoadingDelete }] = useDeleteGuestGroupMutation();

    const { 0: GROUPS, 1: SECTIONS, 2: SUB_SECTIONS } = groupBy(guestGroups, 'depth');
    const guestGroupsByDepth = { GROUPS, SECTIONS, SUB_SECTIONS };

    const handleOpenModal = (guestGroup: GuestGroupWithGuestsCount) => {
        setGroupToDelete(guestGroup);
    };

    const handleDeleteGroup = () =>
        groupToDelete && deleteGuestGroup({ guestGroupId: groupToDelete.guestGroupId, token });
    const handleCloseModal = () => setGroupToDelete(null);
    const handleUpdateClick = useCallback(
        (groupId: number, depth: GuestGroupsByDepthKey) => {
            const path =
                depth === GuestGroupsByDepthKey.GROUPS
                    ? Routes.EditGroup
                    : depth === GuestGroupsByDepthKey.SECTIONS
                    ? Routes.EditSection
                    : Routes.EditSubSection;
            history.push(generatePath(path, { brandId, groupId }));
        },
        [brandId, history],
    );
    const handleAddGroup = useCallback(
        (depth: GuestGroupsByDepthKey) => {
            const path =
                depth === GuestGroupsByDepthKey.GROUPS
                    ? Routes.CreateGroup
                    : depth === GuestGroupsByDepthKey.SECTIONS
                    ? Routes.CreateSection
                    : Routes.CreateSubSection;
            history.push(generatePath(path, { brandId }));
        },
        [brandId, history],
    );

    const groupsTableRowsByDepth = Object.fromEntries(
        Object.values(GuestGroupsByDepthKey).map((key) => [
            key,
            getGroupsTableRowsVM(intl, handleOpenModal, guestGroupsByDepth[key as GuestGroupsByDepthKey]),
        ]),
    ) as GroupsTableRowsByDepth;

    const groupsTableColumnsByDepth = Object.fromEntries(
        Object.values(GuestGroupsByDepthKey).map((key) => [
            key,
            getGroupsTableColumnsVM(intl, key as GuestGroupsByDepthKey),
        ]),
    ) as GroupsTableColumnsByDepth;

    const handleOnRowClick = useCallback(
        (cell: Cell<TableRow, TableCell<unknown>> | undefined) => {
            if (cell && cell.column.id !== 'action')
                handleUpdateClick(
                    parseInt(cell.row.id, 10),
                    (cell.row.original.depth.value as { text: string }).text as GuestGroupsByDepthKey,
                );
        },
        [handleUpdateClick],
    );
    useEffect(() => {
        isSuccess && setGroupToDelete(null);
    }, [isSuccess]);

    return {
        brandId,
        groupToDelete,
        guestGroups,
        guestGroupMapByDepth,
        groupsTableRowsByDepth,
        isLoading,
        isLoadingDelete,
        groupsTableColumnsByDepth,
        handleCloseModal,
        handleDeleteGroup,
        handleAddGroup,
        handleOnRowClick,
    };
};
