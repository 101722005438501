import { Title } from '@/components/atoms/Title';
import React from 'react';
import { Container, Header, ContentWrapper, Form, StyledButton } from './UpdatePassword.style';
import { Controller } from 'react-hook-form';
import { Input } from '@/components/atoms/Form/Input';
import { computeText } from '@/locales/utils';
import { useIntl } from 'react-intl';

import useUpdatePasswordVM from './UpdatePassword.viewmodel';
import { SuccessMessage } from './UpdatePassword.style';

const UpdatePassword = () => {
    const intl = useIntl();
    const { handleSubmit, onSubmit, control, isValid, isLoading, getValues, isSuccess } = useUpdatePasswordVM();

    return (
        <Container>
            <ContentWrapper>
                <Header>
                    <Title text={computeText(intl, 'passwordUpdate.title')} />
                </Header>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="currentPassword"
                        control={control}
                        rules={{ required: computeText(intl, 'field.required') }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={(e) => {
                                    onChange(e.target.value);
                                }}
                                labelId="passwordUpdate.currentPassword"
                                placeholder="passwordUpdate.currentPassword"
                                value={value}
                                type="password"
                                error={error?.message}
                            />
                        )}
                    />
                    <Controller
                        name="newPassword"
                        control={control}
                        rules={{
                            required: computeText(intl, 'field.required'),
                            minLength: {
                                value: 12,
                                message: computeText(intl, 'passwordUpdate.passwordMinLength'),
                            },
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={(e) => {
                                    onChange(e.target.value);
                                }}
                                labelId="passwordUpdate.newPassword"
                                placeholder="passwordUpdate.newPassword"
                                value={value}
                                type="password"
                                error={error?.message}
                            />
                        )}
                    />
                    <Controller
                        name="confirmPassword"
                        control={control}
                        rules={{
                            required: computeText(intl, 'field.required'),
                            validate: (value) => {
                                return (
                                    value === getValues('newPassword') ||
                                    computeText(intl, 'passwordUpdate.passwordMismatch')
                                );
                            },
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={(e) => {
                                    onChange(e.target.value);
                                }}
                                labelId="passwordUpdate.confirmPassword"
                                placeholder="passwordUpdate.confirmPassword"
                                value={value}
                                type="password"
                                error={error?.message}
                            />
                        )}
                    />
                    {isSuccess && <SuccessMessage>{computeText(intl, 'passwordUpdate.success')}</SuccessMessage>}
                    <StyledButton isLoading={isLoading} type="submit" disabled={!isValid}>
                        {computeText(intl, 'passwordUpdate.update')}
                    </StyledButton>
                </Form>
            </ContentWrapper>
        </Container>
    );
};

export default UpdatePassword;
