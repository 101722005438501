import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { Text } from '@/components/atoms/Text';
import { Title } from '@/components/atoms/Title';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { useGetPosDevicesQuery } from '@/services/innovorder/posDevice/posDevice.endpoints';
import Tabs from '@/components/organisms/Tabs/Tabs';
import Tab from '@/components/organisms/Tabs/Tab';
import Loader from '@/components/atoms/Loader';
import { computeText } from '@/locales/utils';
import { ENV } from '@/utils/env';
import { useGetBrandQuery } from '@/services/innovorder/brand/brand.endpoints';
import { useGetRestaurantChannelsQuery } from '@/services/innovorder/channel/channel.endpoints';
import {
    useGetModuleIngenicoQuery,
    useGetModuleNeptingQuery,
    useGetModulePaygreenQuery,
    useGetModuleStripesQuery,
    useLazyGetModuleIngenicoTpeQuery,
} from '@/services/innovorder/modules/modules.endpoints';
import { useGetRestaurantConfigQuery } from '@/services/innovorder/io-pay/configurations/configurations.endpoints';
import { useGetEdenredConfigQuery } from '@/services/innovorder/edenred/configurations/configurations.endpoints';
import { ChannelId } from '@/services/innovorder/channel/channel.type';
import { getUserToken } from '@/redux/user';
import { getUseNewPaymentMethodsPage } from '@/redux/launchDarkly';
import { useUrlQueryState } from '@/hooks/routes/useUrlQueryState';
import { routes } from '@/core/router/routes';
import { redirectToPaymentMethodsPage } from './PaymentMethods.utils';
import { Container, Subtitle, Header, HeaderLeft, Content } from './PaymentMethods.style';
import { KioskProps, PaymentMethodKioskTab } from './tabs/Kiosk';
import { PaymentMethodWebTab, WebProps } from './tabs/Web';
import { useGetCrousModuleQuery } from '@/services/innovorder/moduleCrous/moduleCrous.endpoints';

type ParamTypes = {
    brandId: string;
    restaurantId: string;
};

const PAYMENT_METHODS_TABS = [
    {
        channelId: ChannelId.POS,
        title: 'paymentMethods.tab.pos',
        component: () => <Fragment />,
        redirectToV1: true,
    },
    {
        channelId: ChannelId.WEB,
        title: 'paymentMethods.tab.web',
        component: (props: WebProps) => <PaymentMethodWebTab {...props} />,
        redirectToV1: false,
    },
    {
        channelId: ChannelId.KIOSK,
        title: 'paymentMethods.tab.kiosk',
        component: (props: KioskProps) => <PaymentMethodKioskTab {...props} />,
        redirectToV1: false,
    },
];

const PaymentMethods: React.FunctionComponent<React.PropsWithChildren> = () => {
    const intl = useIntl();
    const [query, setQueryParam] = useUrlQueryState();
    const token = useSelector(getUserToken);
    const { brandId, restaurantId } = useParams<ParamTypes>();
    const shouldUseNewPaymentMethodsPage = useSelector(getUseNewPaymentMethodsPage);
    const { data, isFetching } = useGetRestaurantChannelsQuery({ restaurantId, token });
    const { data: dataBrand, isFetching: isFetchingBrand } = useGetBrandQuery({
        brandId: Number(brandId),
        token,
    });
    const { data: dataIOPay, isFetching: isFetchingIOPay } = useGetRestaurantConfigQuery({
        restaurantId: Number(restaurantId),
        token,
    });
    const { data: dataEdenred, isFetching: isFetchingEdenred } = useGetEdenredConfigQuery({
        restaurantId: Number(restaurantId),
        token,
    });
    const { data: dataCrous, isLoading: isFetchingCrous } = useGetCrousModuleQuery({ restaurantId, token: token! });
    const { data: dataIngenico, isFetching: isFetchingIngenico } = useGetModuleIngenicoQuery({ restaurantId, token });
    const { data: dataNepting, isFetching: isFetchingNepting } = useGetModuleNeptingQuery({ restaurantId, token });
    const { data: dataPaygreen, isFetching: isFetchingPaygreen } = useGetModulePaygreenQuery({ restaurantId, token });
    const { data: dataStripe, isFetching: isFetchingStripe } = useGetModuleStripesQuery({ restaurantId, token });
    const [getModuleIngenicoTpe, { data: dataIngenicoTpe, isFetching: isFetchingIngenicoTpe }] =
        useLazyGetModuleIngenicoTpeQuery();
    const posDeviceResponse = useGetPosDevicesQuery({
        brandId: Number(brandId),
        restaurantId: Number(restaurantId),
    });
    const posDeviceList = useMemo(() => posDeviceResponse.data, [posDeviceResponse.data]);
    const tabs = useMemo(
        () =>
            PAYMENT_METHODS_TABS.filter((tab) =>
                Object.values(data || {}).find(({ channelId }) =>
                    tab.channelId === ChannelId.POS
                        ? channelId === tab.channelId && posDeviceList && posDeviceList.length > 0
                        : channelId === tab.channelId,
                ),
            ),
        [data, posDeviceList],
    );
    const selectedTab = useMemo(() => {
        const activeIndex = tabs.findIndex(({ channelId }) => query.get('channelId') === `${channelId}`);
        return activeIndex < 0 ? 0 : activeIndex;
    }, [tabs, query]);

    const onRedirectToV1 = useCallback(
        () => redirectToPaymentMethodsPage(brandId, restaurantId),
        [brandId, restaurantId],
    );

    const onTabChange = (index: number) => {
        tabs[index].redirectToV1 && onRedirectToV1();
        !tabs[index].redirectToV1 && setQueryParam('channelId', `${tabs[index].channelId}`);
    };

    useEffect(() => {
        !shouldUseNewPaymentMethodsPage && onRedirectToV1();
    }, [shouldUseNewPaymentMethodsPage, onRedirectToV1]);

    useEffect(() => {
        !isFetching && tabs[selectedTab]?.redirectToV1 && onRedirectToV1();
    }, [isFetching, selectedTab, onRedirectToV1]);

    useEffect(() => {
        !!dataIngenico?.moduleIngenicoId && getModuleIngenicoTpe({ token, moduleId: dataIngenico?.moduleIngenicoId });
    }, [dataIngenico, token, getModuleIngenicoTpe]);

    const tabRoutes = React.useMemo(() => routes.filter(({ tab }) => tab === 'restaurants'), []);

    if (
        isFetching ||
        isFetchingEdenred ||
        isFetchingIOPay ||
        isFetchingBrand ||
        isFetchingIngenico ||
        isFetchingNepting ||
        isFetchingPaygreen ||
        isFetchingStripe ||
        isFetchingIngenicoTpe ||
        isFetchingCrous
    ) {
        return <Loader withContainer />;
    }

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            <Container>
                <Header image={`${ENV.STATIC_URL}/dashboard/images/payment/header-img.jpg`}>
                    <HeaderLeft>
                        <Title text="paymentMethods.tableCard.title" />
                        <Subtitle>
                            <Text text="paymentMethods.tableCard.subtitle" />
                        </Subtitle>
                    </HeaderLeft>
                </Header>
                <Content>
                    <Tabs onSelectTab={onTabChange} selectedTabIndex={selectedTab}>
                        {tabs.map(({ title, channelId, component }) => (
                            <Tab
                                key={`tab:${channelId}`}
                                title={computeText(intl, title)}
                                component={component({
                                    restaurantId,
                                    channelId,
                                    brand: dataBrand,
                                    ingenicoConfig: dataIngenico,
                                    neptingConfig: dataNepting,
                                    paygreenConfig: dataPaygreen,
                                    stripeConfig: dataStripe,
                                    ioPayConfig: dataIOPay,
                                    edenredConfig: dataEdenred,
                                    ingenicoTpeList: dataIngenicoTpe,
                                    crousConfig: dataCrous,
                                })}
                            ></Tab>
                        ))}
                    </Tabs>
                </Content>
            </Container>
        </RouterNavigationTabs>
    );
};

export { PaymentMethods };
