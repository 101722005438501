import React from 'react';
import { Row, TableBodyPropGetter, TableBodyProps, Cell, TableCommonProps } from 'react-table';

import { SvyTooltip } from '@/components/savory/SvyTooltip';

import { TableRow, TableCell } from '../Table.type';

type NormalTableProps = {
    page: Row<TableRow>[];
    prepareRow: (row: Row<TableRow>) => void;
    getTableBodyProps: (propGetter?: TableBodyPropGetter<TableRow> | undefined) => TableBodyProps;
    isCellClickable: (cell: Cell<TableRow, TableCell>) => boolean;
    handleOnCellClick: (cell: Cell<TableRow, TableCell>, event?: React.MouseEvent<HTMLTableCellElement>) => void;
    customRowProps?: (row: TableRow) => TableCommonProps;
};

export const NormalTable: React.FunctionComponent<React.PropsWithChildren<NormalTableProps>> = ({
    page,
    prepareRow,
    getTableBodyProps,
    isCellClickable,
    handleOnCellClick,
    customRowProps,
}) => {
    return (
        <tbody {...getTableBodyProps()}>
            {page.map((row) => {
                prepareRow(row);
                const { key: rowKey, ...tableRowProps } = row.getRowProps(
                    customRowProps ? customRowProps(row.original) : {},
                );

                return (
                    <tr key={rowKey} {...tableRowProps}>
                        {row.cells.map((cell) => {
                            const { key: cellKey, ...cellProps } = cell.getCellProps();
                            const renderedCell = cell.render('Cell');

                            return (
                                <td
                                    key={cellKey}
                                    {...cellProps}
                                    style={{
                                        minWidth: cell.column.minWidth,
                                        width: cell.column.width,
                                        maxWidth: cell.column.maxWidth,
                                    }}
                                    className={isCellClickable(cell) ? 'clickable' : ''}
                                    onClick={(event) => handleOnCellClick(cell, event)}
                                >
                                    {cell?.value?.tooltip ? (
                                        <SvyTooltip tooltipContent={cell?.value?.tooltip}>
                                            <div>{renderedCell}</div>
                                        </SvyTooltip>
                                    ) : (
                                        renderedCell
                                    )}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
};
