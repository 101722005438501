import React, { useCallback } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { useIntl } from 'react-intl';
import { Cell, Column } from 'react-table';
import { computeText } from '@/locales/utils';
import type { TableCell, TableRow } from '@/components/atoms/Table/Table.type';
import { useGetPosAccessRolesQuery } from '@/services/innovorder/pos_access/pos_access_role/pos_access_role.endpoints';
import { Routes } from '@/core/router/routes.types';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';

export type MenuRolesViewModel = {
    headers: Column<TableRow>[];
    rows: TableRow[];
    isLoading: boolean;
    handleOnRowClick: (
        cell: Cell<TableRow, TableCell<unknown>> | undefined,
        event?: React.MouseEvent<HTMLTableCellElement>,
    ) => void;
};

export function useRolesTableVM(): MenuRolesViewModel {
    const intl = useIntl();
    const history = useHistory();
    const {
        params: { brandId, restaurantId },
    } = useRouteMatch<{ brandId: string; restaurantId: string }>();
    const { data: roles = [], isLoading } = useGetPosAccessRolesQuery({
        restaurantId: Number(restaurantId),
    });

    const handleEditClick = React.useCallback(
        (roleId: number) => {
            history.push(generatePath(Routes.EditPosAccessRole, { brandId, restaurantId, roleId }));
        },
        [brandId, history, restaurantId],
    );

    const handleOnRowClick = useCallback((cell: Cell<TableRow, TableCell<unknown>> | undefined) => {
        if (cell && cell.column.id !== 'action') cell.row.values.action.value.items[0].onClick();
    }, []);

    const headers = React.useMemo(
        () => [
            {
                Header: computeText(intl, 'name.name'),
                accessor: 'name',
                width: '20%',
            },
            {
                Header: computeText(intl, 'permissions.permissions'),
                accessor: 'permissions',
                width: '70%',
            },
            {
                Header: computeText(intl, 'action.action'),
                accessor: 'action',
                width: '10%',
                disableSortBy: true,
            },
        ],
        [intl],
    );

    const buildRowDropdownActions = (roleId: number) => [
        {
            value: 1,
            label: computeText(intl, 'action.edit'),
            onClick: () => {
                handleEditClick(roleId);
            },
        },
    ];

    const rows: TableRow[] = React.useMemo(() => {
        if (isLoading) {
            return [];
        }
        return roles.map(({ posRoleId, key, permissions }) => {
            return {
                name: {
                    type: 'string',
                    value: {
                        text: computeText(intl, `posRole.${key}`),
                    },
                },
                permissions: {
                    type: 'string',
                    value: {
                        text: `${permissions.length} permission(s)`,
                    },
                },
                action: {
                    type: 'dropdownText',
                    value: {
                        button: <SVGIcon icon={SVGIcons.MORE_VERTICAL} size={16} />,
                        align: 'end',
                        withPortal: true,
                        items: buildRowDropdownActions(posRoleId),
                        type: 'text',
                    },
                },
            };
        });
    }, [handleEditClick, intl, isLoading, roles]);

    return {
        headers,
        rows,
        isLoading,
        handleOnRowClick,
    };
}
