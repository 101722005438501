import { ENV } from '@/utils/env';
import { innovorderApi } from '../../index';
import { Invoice, InvoiceDownloadLink } from './invoices.type';

const configurationApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getInvoices: build.query<Invoice[], void>({
            query: () => ({
                url: new URL(`/v1/billing/invoices`, ENV.PAYMENT_SERVICE_URL).href,
                method: 'GET',
            }),
            extraOptions: { maxRetries: 3 },
        }),

        getInvoiceDownloadLink: build.query<InvoiceDownloadLink, { invoiceId: string }>({
            query: ({ invoiceId }) => ({
                url: new URL(`/v1/billing/invoices/${invoiceId}/download`, ENV.PAYMENT_SERVICE_URL).href,
                method: 'GET',
            }),
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useGetInvoicesQuery, useLazyGetInvoiceDownloadLinkQuery } = configurationApi;
