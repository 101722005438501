import {
    ICustomerAttributes,
    StudentAttributes,
    UpdateCustomerType,
    UpdateCustomerPayload,
    ACCOUNT_PAYMENT_TYPE,
    UpdateCustomerV2Type,
} from '@/services/innovorder/customer/customer.types';
import { getAccountPaymentType, getAccountType } from '@/services/innovorder/customer/customer.utils';
import { GuestGroupWithGuestsCount } from '@/services/innovorder/guestGroup/guestGroup.type';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { isString } from 'lodash';

export const isPostPaymentAccountPaymentType = (accountPaymentType: string): boolean => {
    return [ACCOUNT_PAYMENT_TYPE.POSTPAYMENT_BANKDEBIT, ACCOUNT_PAYMENT_TYPE.POSTPAYMENT_GUEST].includes(
        accountPaymentType,
    );
};

export const POST_PAYMENT_EWALLET_OVERDRAFT_DEFAULT_AMOUNT = 1000000;

export const getEWalletOverdraftPayload = (selectedPaymentType: string) => {
    if (isPostPaymentAccountPaymentType(selectedPaymentType)) {
        return {
            paymentDailyLimit: null,
            overrideDailyPaymentLimit: false,
        };
    }

    return {};
};
export const prepareUpdateCustomerPayload = (
    customer: ICustomerAttributes,
    formValues: UpdateCustomerV2Type,
): UpdateCustomerPayload => {
    const { customerId } = customer;

    const {
        accountPaymentType,
        badgeNumber,
        grantCode,
        entranceFeeCode,
        studentNumber,
        tariffCode,
        groupId,
        sectionId,
        subSectionId,
        pricingRuleId,
        isBadgeBlocked,
        ewalletOverdraftAmount,
        overrideEwalletOverdraft,
    } = formValues;

    return {
        ewalletOverdraftAmount,
        overrideEwalletOverdraft,
        isBadgeBlocked,
        badgeNumber,
        customerId,
        ...getEWalletOverdraftPayload(accountPaymentType),
        student: {
            ...getAccountType(accountPaymentType),
            guestGroupId: subSectionId || sectionId || groupId,
            pricingRuleId,
            studentNumber,
            tariffCode,
            grantCode,
            entranceFeeCode,
        },
    };
};
const getAllParentGuestGroups = (guestGroups: GuestGroupWithGuestsCount[], guestGroupId: number | null) => {
    const result: GuestGroupWithGuestsCount[] = [];
    if (!guestGroupId) return result;

    const collectParents = (currentId: number) => {
        const currentGroup = guestGroups.find((group) => group.guestGroupId === currentId);
        if (currentGroup) {
            result.push(currentGroup);
            if (currentGroup.parentGuestGroupId) {
                collectParents(currentGroup.parentGuestGroupId);
            }
        }
    };

    collectParents(guestGroupId);
    return result.sort((a, b) => a.depth - b.depth);
};

const getStudentDefaultValues = (
    student: StudentAttributes,
    guestGroups: GuestGroupWithGuestsCount[],
): Partial<UpdateCustomerType> & {
    groupId?: number;
    sectionId?: number;
    subSectionId?: number;
    pricingRuleId?: number;
} => {
    const {
        tariffCode,
        studentNumber,
        guestGroupId,
        accountPaymentType: groupAccountPaymentType,
        accountType,
        entranceFeeCode,
        grantCode,
        pricingRuleId,
    } = student;
    const populatedGuestGroup = getAllParentGuestGroups(guestGroups, guestGroupId);
    const accountPaymentType = getAccountPaymentType(groupAccountPaymentType, accountType);
    return {
        tariffCode: tariffCode || undefined,
        studentNumber: studentNumber || undefined,
        accountPaymentType: accountPaymentType || undefined,
        grantCode: grantCode || undefined,
        entranceFeeCode: entranceFeeCode || undefined,
        groupId: populatedGuestGroup[0]?.guestGroupId,
        sectionId: populatedGuestGroup[1]?.guestGroupId,
        subSectionId: populatedGuestGroup[2]?.guestGroupId,
        pricingRuleId: pricingRuleId || populatedGuestGroup[0]?.pricingRules[0]?.pricingRuleId,
    };
};

export const getEwalletOverdraftValues = ({ paymentDailyLimit, ewalletOverdraftAmount }: ICustomerAttributes) => ({
    paymentDailyLimit,
    overrideDailyPaymentLimit: paymentDailyLimit != null,
    overrideEwalletOverdraft: ewalletOverdraftAmount != null,
    ewalletOverdraftAmount: ewalletOverdraftAmount ?? undefined,
});

export const getCustomerDefaultValues = (
    customer: ICustomerAttributes | undefined,
    guestGroups: GuestGroupWithGuestsCount[],
): Partial<UpdateCustomerV2Type> => {
    if (!customer) {
        return {};
    }

    const { badgeNumber, isBadgeBlocked } = customer;

    if (!customer.student) {
        return {
            isBadgeBlocked,
            badgeNumber: isString(badgeNumber) ? badgeNumber : undefined,
        };
    }

    const studentDefaultValues = getStudentDefaultValues(customer.student, guestGroups);
    const ewalletOverdraftValues = getEwalletOverdraftValues(customer);
    return {
        isBadgeBlocked,
        badgeNumber: isString(badgeNumber) ? badgeNumber : undefined,
        ...ewalletOverdraftValues,
        ...studentDefaultValues,
    };
};

type FormErrorData = {
    code: string;
    message: string;
};

export const getErrorMessage = (errors: FetchBaseQueryError[]): string => {
    return errors.reduce(
        (message, error: FetchBaseQueryError) => message || (error?.data as FormErrorData)?.message,
        '',
    );
};
