import React from 'react';

import { Select } from '@/components/form/Select';
import { Input } from '@/components/form/Input';
import { FlexContainer, WarningMessage } from './PricingRuleForm.style';
import { useIntl, FormattedMessage } from 'react-intl';
import { usePricingRuleFormVM } from './PricingRuleForm.viewmodel';
import { computeText } from '@/locales/utils';

type PricingRuleFormProps = {
    brandId: number;
};

const PricingRuleForm: React.FunctionComponent<React.PropsWithChildren<PricingRuleFormProps>> = ({ brandId }) => {
    const intl = useIntl();
    const { pricingRules, isFetchingPricingRules } = usePricingRuleFormVM({ brandId });

    return (
        <div>
            <WarningMessage>
                <FormattedMessage
                    id={`customer.student.pricing.rules.deprecated`}
                    values={{
                        moreInfo: (
                            <a
                                href={`https://help.innovorder.fr/hc/fr/articles/14396587730588-Mise-%C3%A0-jour-de-la-fiche-convives-Les-r%C3%A9gimes-tarifaires`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FormattedMessage id="customer.student.pricing.rules.more_info" />
                            </a>
                        ),
                    }}
                />
            </WarningMessage>
            <FlexContainer>
                <Input labelId="customer.student.pricing.rules.grant.code" name={'grantCode'} disabled />
                <Input labelId="customer.student.pricing.rules.entrance.fee.code" name={'entranceFeeCode'} disabled />
                <Select
                    labelId="customer.student.pricing.rules.code"
                    name={'pricingRuleId'}
                    rules={{
                        required: computeText(intl, 'pricingRule.modal.required'),
                    }}
                    options={pricingRules}
                    isLoading={isFetchingPricingRules}
                />
            </FlexContainer>
        </div>
    );
};

export default PricingRuleForm;
