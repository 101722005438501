import React from 'react';

import { Text } from '@/components/atoms/Text';
import { OmnichannelOrder } from '@/services/innovorder/orders/order.type';
import { useIntl } from 'react-intl';
import {
    Chips,
    ChipsContainer,
    ChipsValue,
    Container,
    HeaderContainer,
    KpiContainer,
    StyledCallout,
    StyledTitle,
    Subtitle,
    TitleContainer,
} from './OrderDetailsHeader.style';
import Kpi from './OrderDetailsHeaderKpi';
import { getPaymentStatus, shouldDisplayPaymentKpi } from './OrderDetailsHeader.utils';

type OrderDetailsHeaderProps = Pick<
    OmnichannelOrder,
    | 'channelId'
    | 'currency'
    | 'numberOfGuests'
    | 'startDate'
    | 'status'
    | 'tableName'
    | 'expectedDate'
    | 'webPaymentStatus'
    | 'shift'
    | 'payments'
    | 'totalPriceDiscountedWithTaxIncluded'
>;

const OrderDetailsHeader: React.FunctionComponent<React.PropsWithChildren<OrderDetailsHeaderProps>> = ({
    channelId,
    currency,
    numberOfGuests,
    startDate,
    expectedDate,
    status,
    tableName,
    webPaymentStatus,
    shift,
    payments,
    totalPriceDiscountedWithTaxIncluded,
}) => {
    const paymentStatus = getPaymentStatus(status, webPaymentStatus);
    const isIncoherent = paymentStatus === 'PAID' && payments.length === 0 && totalPriceDiscountedWithTaxIncluded > 0;

    const orderDate = new Date(startDate);
    const deliveryDate = expectedDate ? new Date(expectedDate) : undefined;

    const { formatMessage } = useIntl();

    return (
        <Container>
            <HeaderContainer>
                <TitleContainer>
                    {isIncoherent && (
                        <StyledCallout
                            data-testid="order-details-header-incoherent"
                            variant="danger"
                            text={formatMessage({ id: 'orderDetail.error.incoherent' })}
                        />
                    )}
                    <StyledTitle text={`orderDetail.title.${channelId}`} />
                    <Subtitle text={`orderDetail.status.${status}`} />
                </TitleContainer>
                <ChipsContainer>
                    {shift && (
                        <Chips>
                            <ChipsValue>
                                <Text text={shift.name === '(default)' ? 'orderDetail.shift.default' : shift.name} />
                            </ChipsValue>
                        </Chips>
                    )}
                    <Chips>
                        <Text text="orderDetail.header.guestNumber" />
                        <ChipsValue>{numberOfGuests}</ChipsValue>
                    </Chips>
                    <Chips>
                        <Text text="orderDetail.header.currency" />
                        <ChipsValue>{currency}</ChipsValue>
                    </Chips>
                </ChipsContainer>
            </HeaderContainer>
            <KpiContainer>
                <Kpi
                    title="orderDetail.header.orderedAt"
                    text="orderDetail.kpi.time"
                    values={{ date: orderDate }}
                    date={orderDate}
                />
                {deliveryDate && (
                    <Kpi
                        title="orderDetail.header.deliverAt"
                        text="orderDetail.kpi.time"
                        values={{ date: deliveryDate }}
                        date={deliveryDate}
                    />
                )}
                {shouldDisplayPaymentKpi(paymentStatus) && (
                    <Kpi title="orderDetail.header.payment" text={`orderDetail.paymentStatus.${paymentStatus}`} />
                )}
                {tableName && <Kpi title="orderDetail.header.table" text={tableName} />}
            </KpiContainer>
        </Container>
    );
};

export default OrderDetailsHeader;
