export type EditCustomerLoyaltiesRequest = {
    brandId: number;
    loyaltyAmountToAdd: number;
    emailsOrCards: string;
};

export type EditCustomerLoyaltiesResponse = {
    data: EditCustomerLoyaltiesResult;
};

export type EditCustomerLoyaltiesResult = {
    success: boolean;
};

export type UnarchiveCustomerRequest = {
    customerId: string;
};

export type UnarchiveCustomerResponse = {
    data: UnarchiveCustomerResult;
};

export type UnarchiveCustomerResult = {
    success: boolean;
    data?: {
        error: string;
    };
};

export type AddUserToRestaurantRequest = {
    email: string;
    restaurantId: string;
};

export type AddUserToRestaurantResponse = {
    data: AddUserToRestaurantResult;
};

export type AddUserToRestaurantResult = {
    success: boolean;
    data?: {
        error: string;
    };
};

export type DeleteUnassignedImagesRequest = {
    brandId: number;
};

export type DeleteUnassignedImagesResponse = {
    data: DeleteUnassignedImagesResult;
};

export type DeleteUnassignedImagesResult = {
    success: boolean;
    data?: {
        error: string;
    };
};

export type DeleteTransactionRequest = {
    transactionId: string;
};

export type DeleteTransactionResponse = {
    data: DeleteUnassignedImagesResult;
};

export type DeleteTransactionResult = {
    success: boolean;
    data?: {
        error: string;
    };
};

export type ImportGeojsonPolygonRequest = {
    restaurantId: string;
    areaName: string;
    geoJsonPolygon: string;
};

export type ImportGeojsonPolygonResponse = {
    data: ImportGeojsonPolygonResult;
};

export type ImportGeojsonPolygonResult = {
    success: boolean;
    data?: {
        error: string;
    };
};

export type DeleteCustomerRequest = {
    customerId: string;
};

export type DeleteCustomerResponse = {
    data: DeleteCustomerResult;
};

export type DeleteCustomerResult = {
    success: boolean;
    data?: {
        error: string;
    };
};

export type RazCustomerRequest = {
    customerId: string;
    date: string;
};

export type RazCustomerResponse = {
    data: DeleteCustomerResult;
};

export type RazCustomerResult = {
    success: boolean;
    data?: {
        error: string;
    };
};

export type CopySchedulesRequest = {
    scheduleType: string;
    idSource: string;
    idsDestination: string;
};

export type CopySchedulesResponse = {
    data: CopySchedulesResult;
};

export type CopySchedulesResult = {
    success: boolean;
    data?: {
        error: string;
    };
};

export type EditProductSkuFromProductIdRequest = {
    file: File;
};

export type EditProductSkuFromProductIdResponse = {
    data: EditProductSkuFromProductIdResult;
};

export type EditProductSkuFromProductIdResult = {
    success: boolean;
    data?: {
        error: string;
    };
};

export type ImportProductInMenuRequest = {
    file: File;
};

export type ImportProductInMenuResponse = {
    data: ImportProductInMenuResult;
};

export type ImportProductInMenuResult = {
    success: boolean;
    data?: {
        error: string;
    };
};

export type ImportProductFromSkuRequest = {
    file: File;
};

export type ImportProductFromSkuResponse = {
    data: ImportProductFromSkuResult;
};

export type ImportProductFromSkuResult = {
    success: boolean;
    data?: {
        error: string;
    };
};

export type ImportBackofficeUsersRequest = {
    file: File;
};

export type ImportBackofficeUsersResponse = {
    data: ImportProductInMenuResult;
};

export type ImportBackofficeUsersResult = {
    success: boolean;
    data?: {
        error: string;
    };
};

export type EditProductTagFromProductIdRequest = {
    file: File;
};

export type EditProductTagFromProductIdResponse = {
    data: EditProductTagFromProductIdResult;
};

export type EditProductTagFromProductIdResult = {
    success: boolean;
    data?: {
        error: string;
    };
};

export type MakeEwalletTransactionRequest = {
    file: File;
};

export type MakeEwalletTransactionResponse = {
    data: MakeEwalletTransactionResult;
};

export type MakeEwalletTransactionResult = {
    success: boolean;
    data?: {
        error: string;
    };
};

export type EditPasswordExpiresAtRequest = {
    shouldResetPassword: string;
    brandId: string;
    customerId: string;
};

export type EditPasswordExpiresAtResponse = {
    data: EditPasswordExpiresAtResult;
};

export type EditPasswordExpiresAtResult = {
    success: boolean;
    data?: {
        error: string;
    };
};

export type EditProductInfoFromProductIdRequest = {
    file: File;
};

export type EditProductInfoFromProductIdResponse = {
    data: EditProductInfoFromProductIdResult;
};

export type EditProductInfoFromProductIdResult = {
    success: boolean;
    data?: {
        error: string;
    };
};

export type EditProductPriceInAdminToolsRequest = {
    file: File;
};

export type EditProductPriceInAdminToolsResponse = {
    data: EditProductPriceInAdminToolsResult;
};

export type EditProductPriceInAdminToolsResult = {
    success: boolean;
    data?: {
        error: string;
    };
};

export enum FileFormat {
    JES = 'JES',
    X_GIL = 'X-GIL',
    CSI = 'CSI',
    PI_ELECTRONICS = 'PI-ELECTRONICS',
}

export type TransformGuestFileRequest = {
    file: File;
    fileFormat: FileFormat;
};

export type TransformGuestFileResponse = {
    data: TransformGuestFileResult;
};

export type TransformGuestFileResult = {
    url: string;
};

export type CopyProductFromMenuIdToMenuIdRequest = {
    menuIdSource: string;
    menuIdDestination: string;
    filterCategory?: string;
    filterTag?: string;
};

export type CopyProductFromMenuIdToMenuIdResponse = {
    data: CopyProductFromMenuIdToMenuIdResult;
};

export type CopyProductFromMenuIdToMenuIdResult = {
    success: boolean;
    data?: {
        error: string;
    };
};
