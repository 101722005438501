import { Button } from '@/components/atoms/Button';
import { Text } from '@/components/atoms/Text';
import { Title } from '@/components/atoms/Title';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ChannelId } from '@/services/innovorder/channel/channel.type';
import { ConsumptionMode } from '@/services/innovorder/consumptionMode/consumptionMode.type';
import { PaymentMethod, PaymentMethodCode } from '@/services/innovorder/paymentMethod/paymentMethod.type';
import { DraggablePaymentMethods } from '../DraggablePaymentMethods';
import Integration from './Integration/Integration';
import { ActionBar, ActionContainer, Container, Left, Right, Row, Subtitle } from './Tab.style';

export type IntegrationType =
    | 'ingenico'
    | 'nepting'
    | 'stripe'
    | 'iopay'
    | 'ewallet'
    | 'paygreen'
    | 'edenred'
    | 'crous'
    | 'yavin';

export type IntegrationConfig = {
    type: IntegrationType;
    isFull: boolean;
    logoPath: string;
    paymentMethod: PaymentMethod;
    paymentMethodCode: PaymentMethodCode;
    showIntegration: boolean;
    showConfigButton: boolean;
    showConsumptionModes: boolean;
    message?: string;
};

export type TabProps = {
    channelId: ChannelId;
    integrations: IntegrationConfig[];
    consumptionModes: ConsumptionMode[];
    paymentMethods: PaymentMethod[];
    isLoadingConsumptionModes: boolean;
    isLoadingSave: boolean;
    isExternalIdActivated: boolean;
    onChange: (param: PaymentMethod[]) => void;
    onCancel: () => void;
    onSave: () => void;
};

const Tab: React.FunctionComponent<React.PropsWithChildren<TabProps>> = ({
    channelId,
    integrations,
    consumptionModes,
    isLoadingConsumptionModes,
    paymentMethods,
    isLoadingSave,
    isExternalIdActivated,
    onChange,
    onCancel,
    onSave,
}) => {
    const disableSave = !paymentMethods.some(({ action }) => !!action);

    return (
        <Container>
            <Row>
                <Left>
                    <Title text="paymentMethods.tab.kiosk.integration.title" />
                    <Subtitle>
                        <Text text="paymentMethods.tab.kiosk.integration.desc1" />
                    </Subtitle>
                    <Subtitle>
                        <Text text="paymentMethods.tab.kiosk.integration.desc2" />
                    </Subtitle>
                </Left>
                <Right>
                    {integrations.map((integration) => (
                        <Integration
                            key={integration.type}
                            consumptionModes={consumptionModes}
                            paymentMethods={paymentMethods}
                            isLoadingConsumptionModes={isLoadingConsumptionModes}
                            isExternalIdActivated={isExternalIdActivated}
                            integration={integration}
                            onChange={onChange}
                        />
                    ))}
                </Right>
            </Row>
            <Row>
                <Left>
                    <Title text="paymentMethods.tab.kiosk.payment.title" />
                    <Subtitle>
                        <Text text="paymentMethods.tab.kiosk.payment.desc" />
                    </Subtitle>
                </Left>
                <Right>
                    <DraggablePaymentMethods
                        data={paymentMethods}
                        channelId={channelId}
                        consumptionModes={consumptionModes ?? []}
                        isExternalIdActivated={isExternalIdActivated}
                        onChange={onChange}
                    />
                </Right>
            </Row>

            <ActionBar>
                <ActionContainer>
                    <Button buttonType="red" onClick={onCancel} disabled={disableSave}>
                        <FormattedMessage id="button.cancel" />
                    </Button>

                    <Button buttonType="primary" onClick={onSave} isLoading={isLoadingSave} disabled={disableSave}>
                        <FormattedMessage id="button.save" />
                    </Button>
                </ActionContainer>
            </ActionBar>
        </Container>
    );
};

export { Tab };
