import * as React from 'react';

import { computeText } from '@/locales/utils';
import { Input } from '@/components/form/Input';
import { FormattedMessage, useIntl } from 'react-intl';
import { Text } from '@/components/atoms/Text';
import { SvyButton } from '@/components/savory/SvyButton';
import { confirm } from '@/core/asyncActions/confirm';
import { useCopyProductFromMenuIdToMenuIdMutation } from '@/services/innovorder/adminTools/adminTools.endpoints';
import { FormControl, FormControls, SuccessMessage } from '../AdminTools.style';
import { safeMutate } from '@/utils/errors';
import { Form } from '@/core/form/Form';
import { TextContainer } from '@/components/atoms/RadioButton/RadioButton.style';
import { DescriptionList } from '../../GrantsAndEntranceFees/UploadModal/UploadStep/UploadStep.style';

export type CopyProductFromMenuIdToMenuIdInputs = {
    menuIdSource: string;
    menuIdDestination: string;
    filterCategory?: string;
    filterTag?: string;
};

const CopyProductFromMenuIdToMenuId: React.FunctionComponent = () => {
    const intl = useIntl();

    const [copyProductFromMenuIdToMenuId, { isSuccess }] = useCopyProductFromMenuIdToMenuIdMutation();

    const handleSubmitForm = async ({
        menuIdSource,
        menuIdDestination,
        filterCategory,
        filterTag,
    }: CopyProductFromMenuIdToMenuIdInputs) => {
        const hasConfirmed = await confirm(computeText(intl, 'adminTools.confirmModal.description'));
        if (hasConfirmed) {
            await safeMutate(
                copyProductFromMenuIdToMenuId({
                    menuIdSource,
                    menuIdDestination,
                    filterCategory,
                    filterTag,
                }),
            );
        }
    };

    return (
        <Form<CopyProductFromMenuIdToMenuIdInputs>>
            {({ handleSubmit }) => {
                return (
                    <>
                        <Text type="large" text="brand.adminTools.copyProductFromMenuIdToMenuId.title" />
                        <br />
                        <Text text="brand.adminTools.copyProductFromMenuIdToMenuId.description1" />
                        <Text text="brand.adminTools.copyProductFromMenuIdToMenuId.description2" />
                        <Text text="brand.adminTools.copyProductFromMenuIdToMenuId.description3" />
                        <FormControls>
                            <FormControl>
                                <Input
                                    name="menuIdSource"
                                    rules={{ required: computeText(intl, 'field.required') }}
                                    placeholder="brand.adminTools.copyProductFromMenuIdToMenuId.form.placeholder.menuIdSource"
                                    labelId="brand.adminTools.copyProductFromMenuIdToMenuId.form.menuIdSource"
                                />
                                <Input
                                    name="menuIdDestination"
                                    rules={{ required: computeText(intl, 'field.required') }}
                                    placeholder="brand.adminTools.copyProductFromMenuIdToMenuId.form.placeholder.menuIdDestination"
                                    labelId="brand.adminTools.copyProductFromMenuIdToMenuId.form.menuIdDestination"
                                />
                                <Input
                                    name="filterCategory"
                                    placeholder="brand.adminTools.copyProductFromMenuIdToMenuId.form.placeholder.filterCategory"
                                    labelId="brand.adminTools.copyProductFromMenuIdToMenuId.form.filterCategory"
                                />
                                <Input
                                    name="filterTag"
                                    placeholder="brand.adminTools.copyProductFromMenuIdToMenuId.form.placeholder.filterTag"
                                    labelId="brand.adminTools.copyProductFromMenuIdToMenuId.form.filterTag"
                                />
                            </FormControl>
                            <SvyButton onClick={handleSubmit(handleSubmitForm)}>
                                <FormattedMessage id="button.validate" />
                            </SvyButton>
                            {isSuccess && (
                                <SuccessMessage text="brand.adminTools.copyProductFromMenuIdToMenuId.form.success" />
                            )}
                        </FormControls>
                    </>
                );
            }}
        </Form>
    );
};

export default CopyProductFromMenuIdToMenuId;
