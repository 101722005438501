export interface GetInvoicesPayload {
    billingConfigurationId: number;
}

export interface Invoice {
    id: number;
    amount: number;
    status: InvoiceStatus;
    psp: InvoicePsp;
    number: string;
    name: string;
    dueDate: string;
    createdAt: string;
    updatedAt: string;
}

export interface InvoiceDownloadLink {
    downloadLink: string;
}

export enum InvoiceStatus {
    Due = 1,
    Paid = 2,
}

export enum InvoicePsp {
    Stripe = 1,
}
