import { computeText } from '@/locales/utils';
import { GuestGroupWithGuestsCount } from '@/services/innovorder/guestGroup/guestGroup.type';
import { IntlShape } from 'react-intl';

export type GroupExportFormat = {
    group: string;
    section?: string;
    subsection?: string;
    pricingRuleCodes: string[];
};

export const getHeaders = ({ intl }: { intl: IntlShape }) => {
    return [
        computeText(intl, 'group.export.group'),
        computeText(intl, 'group.export.section'),
        computeText(intl, 'group.export.subsection'),
        computeText(intl, 'group.export.pricingRule'),
    ];
};

export const getSheetOptions = ({ intl }: { intl: IntlShape }) => {
    const sheetOptions = {
        group: {
            name: 'Groupe',
            headers: getHeaders({ intl }),
            mapper: (data: GroupExportFormat) => [
                data.group,
                data.section,
                data.subsection,
                data.pricingRuleCodes.join(', '),
            ],
        },
    };

    return sheetOptions;
};

export const formatGuestGroupsForExports = (guestGroups: GuestGroupWithGuestsCount[]): GroupExportFormat[] => {
    const guestGroupsFormattedForExport: GroupExportFormat[] = [];
    guestGroups.forEach((guestGroup) => {
        switch (guestGroup.depth) {
            case 0:
                guestGroupsFormattedForExport.push({
                    group: guestGroup.name,
                    pricingRuleCodes: guestGroup.pricingRules.map((rule) => rule.code),
                });
                break;

            case 1:
                guestGroupsFormattedForExport.push({
                    group: guestGroup.parentGroupName || '',
                    section: guestGroup.name,
                    pricingRuleCodes: guestGroup.pricingRules.map((rule) => rule.code),
                });
                break;

            case 2:
                guestGroupsFormattedForExport.push({
                    group: guestGroup.parentGroupName || '',
                    section: guestGroup.parentSectionName || '',
                    subsection: guestGroup.name,
                    pricingRuleCodes: guestGroup.pricingRules.map((rule) => rule.code),
                });
                break;

            default:
                break;
        }
    });

    return guestGroupsFormattedForExport;
};
