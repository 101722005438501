import React from 'react';
import NavigationTabs from '@/components/organisms/RouterNavigationTabs';
import { routes } from '@/core/router/routes';
import TransformGuestFile from '@/pages/Brand/AdminTools/TransformGuestFile';
import { Container } from './AdminTools.style';
import EditCustomerLoyalties from './EditCustomerLoyalties';
import UnarchiveCustomer from './UnarchiveCustomer';
import AddUserToRestaurant from './AddUserToRestaurant';
import DeleteUnassignedImages from './DeleteUnassignedImages';
import DeleteTransaction from './DeleteTransaction';
import ImportGeojsonPolygon from './ImportGeojsonPolygon';
import DeleteCustomer from './DeleteCustomer';
import RazCustomer from './RazCustomer';
import CopySchedules from './CopySchedules';
import MakeEwalletTransaction from './MakeEwalletTransaction';
import EditPasswordExpiresAt from './EditPasswordExpiresAt';
import ImportProductInMenu from './ImportProductInMenu';
import ImportProductFromSku from './ImportProductFromSku';
import CopyProductFromMenuIdToMenuId from './CopyProductFromMenuIdToMenuId';
import ImportBackofficeUsers from './ImportBackofficeUsers';
import EditProductInfoFromProductId from './EditProductInfoFromProductId';
import EditProductPriceInAdminTools from './EditProductPriceInAdminTools';
const BrandAdminTools: React.FunctionComponent = () => {
    const tabRoutes = React.useMemo(() => routes.filter(({ tab }) => tab === 'brands'), []);
    return (
        <>
            <NavigationTabs routes={tabRoutes}>
                <Container>
                    <EditCustomerLoyalties />
                </Container>
                <Container>
                    <UnarchiveCustomer />
                </Container>
                <Container>
                    <AddUserToRestaurant />
                </Container>
                <Container>
                    <DeleteUnassignedImages />
                </Container>
                <Container>
                    <DeleteTransaction />
                </Container>
                <Container>
                    <ImportGeojsonPolygon />
                </Container>
                <Container>
                    <DeleteCustomer />
                </Container>
                <Container>
                    <CopySchedules />
                </Container>
                <Container>
                    <RazCustomer />
                </Container>
                <Container>
                    <MakeEwalletTransaction />
                </Container>
                <Container>
                    <EditPasswordExpiresAt />
                </Container>
                <Container>
                    <TransformGuestFile />
                </Container>
                <Container>
                    <ImportProductInMenu />
                </Container>
                <Container>
                    <EditProductInfoFromProductId />
                </Container>
                <Container>
                    <EditProductPriceInAdminTools />
                </Container>
                <Container>
                    <ImportBackofficeUsers />
                </Container>
                <Container>
                    <ImportProductFromSku />
                </Container>
                <Container>
                    <CopyProductFromMenuIdToMenuId />
                </Container>
            </NavigationTabs>
        </>
    );
};

export default BrandAdminTools;
