import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { redirectToBackofficeV1 } from '@/utils/backoffice-v1';
import { getUseGuestHierarchy } from '@/redux/launchDarkly';

export type CustomerTableViewModel = {
    onHideCreateModal: () => void;
    handleImport: () => void;
    handleExport: () => void;
    shouldUseGuestHierarchy: boolean;
};

export const useCustomersListVM = ({ brandId }: { brandId: number }): CustomerTableViewModel => {
    const shouldUseGuestHierarchy = useSelector(getUseGuestHierarchy);

    const onHide = useCallback(() => {
        redirectToBackofficeV1('/home/customers');
    }, []);

    const handleImport = useCallback(() => {
        console.log('handleImport');
    }, []);

    const handleExport = useCallback(() => {
        console.log('handleExport');
    }, []);

    return {
        onHideCreateModal: onHide,
        handleImport,
        handleExport,
        shouldUseGuestHierarchy,
    };
};
