import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Container } from './Wizard.style';
import WizardStep from './WizardStep';

export type StepData = {
    title: string;
    component: HTMLElement | ReactElement;
    onPrevious?: () => boolean | Promise<boolean>;
    onNext?: () => boolean | Promise<boolean>;
    onCancel?: () => void;
    onValidate?: () => void;
    showPrevious?: boolean;
    showNext?: boolean;
    isNextDisabled?: boolean;
    showValidate?: boolean;
    showHeader?: boolean;
    nextLabel?: string;
    previousLabel?: string;
    cancelLabel?: string;
    showStepperStep?: boolean;
    disabled?: boolean;
};

export type WizardProps = {
    height?: number;
    width?: number;
    steps: StepData[];
    nextLabel?: string;
    cancelLabel?: string;
    previousLabel?: string;
    validateLabel?: string;
    initialStep?: number;
    isLoading?: boolean;
    isForm?: boolean;
    onValidate: () => void;
    canGoBack?: boolean;
    canValidate?: boolean;
    selectedStep?: number | null;
    errorMessage?: string;
    showStepper?: boolean;
};

const Wizard: React.FunctionComponent<React.PropsWithChildren<WizardProps>> = ({
    height,
    width,
    steps: allSteps,
    onValidate,
    canValidate = true,
    canGoBack = true,
    nextLabel = 'button.next',
    cancelLabel = 'button.cancel',
    previousLabel = 'button.previous',
    validateLabel = 'button.validate',
    initialStep = 0,
    isLoading = false,
    isForm = false,
    selectedStep = null,
    errorMessage,
    showStepper = false,
    ...otherProps
}) => {
    const steps = useMemo(() => allSteps.filter((step) => !step.disabled), [allSteps]);
    const validInitialStep = initialStep < steps.length ? initialStep : 0;
    const [currentStep, setCurrentStep] = useState(validInitialStep);

    useEffect(() => {
        if (selectedStep !== null) {
            setCurrentStep(selectedStep);
        }
    }, [selectedStep, setCurrentStep]);

    const nextStep = () => {
        if (currentStep + 1 < steps.length) {
            setCurrentStep(currentStep + 1);
        }
    };

    const previousStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const getHandleNext = (index: number, step: StepData): (() => void) | undefined => {
        const isLastStep = index === steps.length - 1;
        return async () => {
            if (!isLoading && (step.onNext === undefined || (await step.onNext()))) {
                if (isLastStep) {
                    previousStep();
                } else {
                    nextStep();
                }
            }
        };
    };

    const getHandlePrevious = (index: number, step: StepData): (() => void) | undefined => {
        if (index === 0 && step.showPrevious !== true) {
            return undefined;
        }
        return async () => {
            if (!isLoading && (step.onPrevious === undefined || (await step.onPrevious()))) {
                previousStep();
            }
        };
    };

    const stepperSteps = allSteps
        .filter((step) => step.showStepperStep !== false)
        .map((step) => {
            return {
                label: step.title,
                active:
                    !step.disabled &&
                    allSteps.indexOf(step) <=
                        currentStep + allSteps.slice(0, allSteps.indexOf(step)).filter((s) => s.disabled).length,
                completed: allSteps.indexOf(step) < currentStep,
            };
        });

    return (
        <Container {...otherProps} height={height} width={width}>
            {steps.length > 0 && (
                <WizardStep
                    component={steps[currentStep].component}
                    title={steps[currentStep].title}
                    cancelLabel={steps[currentStep].cancelLabel ?? cancelLabel}
                    nextLabel={steps[currentStep].nextLabel ?? nextLabel}
                    previousLabel={steps[currentStep].previousLabel ?? previousLabel}
                    validateLabel={validateLabel}
                    canValidate={canValidate}
                    isLastStep={currentStep === steps.length - 1}
                    onCancel={steps[currentStep].onCancel}
                    onPrevious={getHandlePrevious(currentStep, steps[currentStep])}
                    onNext={getHandleNext(currentStep, steps[currentStep])}
                    onValidate={steps[currentStep].onValidate || onValidate}
                    showNext={steps[currentStep].showNext}
                    showValidate={steps[currentStep].showValidate}
                    showHeader={steps[currentStep].showHeader}
                    showPrevious={steps[currentStep].showPrevious}
                    showStepper={showStepper}
                    stepperSteps={stepperSteps}
                    errorMessage={errorMessage}
                    isLoading={isLoading}
                    isNextDisabled={steps[currentStep].isNextDisabled}
                    currentStep={currentStep}
                    isForm={isForm}
                    canGoBack={canGoBack}
                />
            )}
        </Container>
    );
};

export default Wizard;
