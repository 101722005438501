export enum BadgeReaderDriver {
    Keyboard = 'keyboard',
    Serial = 'serial',
    Toqla = 'toqla',
}

export enum KeyboardLayout {
    QWERTY = 'QWERTY',
    AZERTY = 'AZERTY',
}

export enum DriverConfigBaudRate {
    BR300 = 300,
    BR600 = 600,
    BR1200 = 1200,
    BR2400 = 2400,
    BR4800 = 4800,
    BR9600 = 9600,
    BR19200 = 19200,
    BR38400 = 38400,
    BR57600 = 57600,
    BR76800 = 76800,
    BR115200 = 115200,
    BR230400 = 230400,
}

export enum DriverConfigDataBits {
    DB5 = 5,
    DB6 = 6,
    DB7 = 7,
    DB8 = 8,
}

export enum DriverConfigStopBits {
    SB1 = 1,
    SB1_5 = 1.5,
    SB2 = 2,
}

export enum DriverConfigParity {
    None = 'none',
    Odd = 'odd',
    Even = 'even',
}

export enum ConvertionType {
    None = 'none',
    HexToString = 'hex-dec',
    StringToHex = 'dec-hex',
    RemoveZero = 'remove-zero',
    QwertyToAzerty = 'qwerty-azerty',
}

export type DriverConfig = {
    baudRate: DriverConfigBaudRate;
    dataBits: DriverConfigDataBits;
    stopBits: DriverConfigStopBits;
    parity: DriverConfigParity;
};

export type ReadingCondition = {
    order: number;
    stringLength: number;
    convertBefore: ConvertionType;
    substrPositionStart: number;
    substrLength: number;
    convertAfter: ConvertionType;
};

export type BadgeReader = {
    badgeReaderId: number;
    brandId: number;
    name: string;
    deviceId: string;
    device_uuid: string;
    driver: BadgeReaderDriver;
    driverConfig: DriverConfig | Record<string, never>;
    readingConditions: ReadingCondition[];
    keyboardLayout: KeyboardLayout;
    createdAt: Date;
    updatedAt: Date;
};

export type GetBadgeReadersPayload = {
    brandId: number;
};

export type DeleteBadgeReadersPayload = Pick<BadgeReader, 'badgeReaderId' | 'brandId'>;

export type CreateBadgeReadersPayload = Omit<BadgeReader, 'badgeReaderId' | 'createdAt' | 'updatedAt'>;

export type UpdateBadgeReadersPayload = Omit<BadgeReader, 'createdAt' | 'updatedAt' | 'brandId'>;
