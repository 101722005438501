import { Listbox } from '@headlessui/react';

import styled from '@emotion/styled';
import { Text } from '@/components/atoms/Text';
import * as React from 'react';

export const SpacedLabel = styled(Text)`
    ${({ theme }): string => theme.font.text.bold}
    margin-right: ${({ theme }) => theme.spacing[4]};
`;

export const SelectContainer = styled.div``;

export const StyledListBox = styled((props: React.ComponentProps<typeof Listbox>) => <Listbox {...props} />)`
    position: relative;
`;

export const StyledListBoxButton = styled((props: React.ComponentProps<typeof Listbox.Button>) => (
    <Listbox.Button {...props} />
))<{ istouched?: string; showValidation?: boolean }>`
    min-height: 40px;
    background-color: ${({ theme }) => theme.color.white200};
    border: 1px solid
        ${({ istouched, showValidation, theme }) =>
            istouched === 'true' && showValidation ? theme.color.emerald : theme.color.grey300};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[3]};
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({ theme }) => theme.color.grey400};
    width: 100%;
    cursor: pointer;
`;

export const ListBoxOptionsContainer = styled.div<{ solidListbox: boolean }>`
    position: ${({ solidListbox }) => (solidListbox ? 'relative' : 'absolute')};
    z-index: 1;
    background-color: ${({ theme }) => theme.color.white200};
    border-radius: ${({ theme }) => theme.radius.main};
    border: 1px solid ${({ theme }) => theme.color.grey300};
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
`;

export const StyledListBoxOptions = styled((props: React.ComponentProps<typeof Listbox.Options>) => (
    <Listbox.Options {...props} />
))`
    width: 100%;
    list-style: none;
`;

export const OptionItem = styled.div`
    cursor: default;
    padding: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[3]};
    width: 100%;
    min-height: 40px;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.color.palePink100};

        & > p {
            color: ${({ theme }) => theme.color.primary};
        }
    }
`;

export const ActionsContainer = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[1]};
`;
