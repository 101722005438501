import * as React from 'react';

import { computeText } from '@/locales/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { Text } from '@/components/atoms/Text';
import { SvyButton } from '@/components/savory/SvyButton';
import { confirm } from '@/core/asyncActions/confirm';
import { useImportProductFromSkuMutation } from '@/services/innovorder/adminTools/adminTools.endpoints';
import { FormControl, FormControls, SuccessMessage } from '../AdminTools.style';
import { safeMutate } from '@/utils/errors';
import { Form } from '@/core/form/Form';
import { FileInput } from '@/components/form/FileInput';
import { Accept } from 'react-dropzone';
import {
    DescriptionList,
    DropzoneContainer,
    TextContainer,
} from '@/pages/MenuList/components/MenuModal/CreateMenuModal/ImportMenuStep/ImportMenuStep.style';

export type ImportProductFromSkuInputs = {
    file: File;
};

const ALLOWED_FILE_TYPES: Accept = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.ms-excel': ['.xls'],
};

const ImportProductFromSku: React.FunctionComponent = () => {
    const intl = useIntl();

    const [importProductFromSku, { isSuccess }] = useImportProductFromSkuMutation();

    const handleSubmitForm = async (file: ImportProductFromSkuInputs) => {
        const hasConfirmed = await confirm(computeText(intl, 'adminTools.confirmModal.description'));
        if (hasConfirmed) {
            await safeMutate(importProductFromSku(file));
        }
    };

    return (
        <Form<ImportProductFromSkuInputs>>
            {({ handleSubmit }) => {
                return (
                    <>
                        <Text type="large" text="brand.adminTools.importProductFromSku.title" />

                        <TextContainer>
                            <DescriptionList>
                                <FormattedMessage
                                    id={`brand.adminTools.importProductFromSku.description1`}
                                    values={{
                                        metabaseLink: (
                                            <a
                                                href={`https://metabase-prod.innovorder.io/question/6254`}
                                                target="_blank"
                                            >
                                                <FormattedMessage id="file.metabaseLink" />
                                            </a>
                                        ),
                                    }}
                                />
                                <br />
                                <FormattedMessage id={`brand.adminTools.importProductFromSku.description1bis`} />
                            </DescriptionList>
                        </TextContainer>
                        <FormControls>
                            <FormControl>
                                <DropzoneContainer>
                                    <React.Suspense>
                                        <FileInput
                                            mode="compact"
                                            accept={ALLOWED_FILE_TYPES}
                                            name="file"
                                            transform={{
                                                output: (file) => file[0],
                                                input: (file: File) => (file ? [file] : []),
                                            }}
                                        />
                                    </React.Suspense>
                                </DropzoneContainer>
                            </FormControl>
                            <SvyButton onClick={handleSubmit(handleSubmitForm)}>
                                <FormattedMessage id="button.validate" />
                            </SvyButton>
                            {isSuccess && <SuccessMessage text="brand.adminTools.importProductFromSku.form.success" />}
                        </FormControls>
                    </>
                );
            }}
        </Form>
    );
};

export default ImportProductFromSku;
