import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import Gate from '@/pages/AuthGate/Gate';
import { Routes } from '@/core/router/routes.types';
import {
    cookieLocation,
    cookieLocationBrandGuest,
    cookieLocationGuest,
    useAuthCookieHandler,
} from '@/hooks/useAuthCookieHandler/useAuthCookieHandler';
import { UserRoles } from '@/services/innovorder/user/user.type';
import { useMeQuery } from '@/services/innovorder/auth/auth.endpoints';
import { useGetBrandQuery } from '@/services/innovorder/brand/brand.endpoints';
import { setBrandFilter } from '@/redux/analyticsFilters';
import { updateUser } from '@/redux/user';
import { updateBrand } from '@/redux/brand';
import { StyledLoader } from './AuthGate.style';

type AuthGateProps = {
    children: React.ReactNode;
};

const AuthGate: React.FunctionComponent<React.PropsWithChildren<AuthGateProps>> = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userId, setUserId] = useState<number | null>();
    const dispatch = useDispatch();
    const { token, isGuest, removeCookie, isGuestBrand } = useAuthCookieHandler();
    const theme = useTheme();

    const UNAUTHORIZED_STATUS_CODES = [401, 403];
    const { data, isError, isLoading, isSuccess, error } = useMeQuery({ token }, { refetchOnMountOrArgChange: true });

    const { data: brand } = useGetBrandQuery(data && data.brandId ? { brandId: data.brandId, token } : skipToken, {
        refetchOnMountOrArgChange: true,
    });

    if (isSuccess && data && (!isLoggedIn || data.userId !== userId)) {
        dispatch(updateUser({ ...data, isGuest, accessToken: token }));
        setUserId(data.userId);
        setIsLoggedIn(true);

        if (data.role !== UserRoles.admin) {
            dispatch(setBrandFilter(data.brands[0]));
        }
    }

    useEffect(() => {
        if (isError && error) {
            const hasStatusCode = 'status' in error;
            if (hasStatusCode && UNAUTHORIZED_STATUS_CODES.includes(error.status as number)) {
                if (!isGuest) removeCookie(cookieLocation);
                if (!isGuestBrand) removeCookie(cookieLocationBrandGuest);
                removeCookie(cookieLocationGuest);
            }
        }
    }, [isError, error, removeCookie]);

    useEffect(() => {
        dispatch(updateBrand(brand || null));
    }, [dispatch, brand]);

    if (isLoading) {
        return <StyledLoader color={theme.color.primary} size={40} withContainer />;
    }

    return (
        <Gate isActive={isLoggedIn} redirect={Routes.Login}>
            {children}
        </Gate>
    );
};

export default AuthGate;
