import {
    ACCOUNT_PAYMENT_TYPE,
    CreateCustomerPayload,
    CreateCustomerType,
} from '@/services/innovorder/customer/customer.types';
import { getAccountType } from '@/services/innovorder/customer/customer.utils';
import { generateBadgeNumber } from '@/utils/factories/customer/customer.utils';
import { BaseCustomerAttributes } from './CreateCustomerModal.types';

export const prepareCreateCustomerPayload = (
    formValues: CreateCustomerType,
    brandId: number,
): CreateCustomerPayload => {
    const { accountPaymentType } = formValues;
    const role = 'customer';

    // remove the id from the badges because it's a field generated by the useFieldArray hook and it is not needed in the payload
    formValues.badges.forEach((badge) => delete badge.id);
    const { groupId, sectionId, subSectionId } = formValues;
    return {
        ...formValues,
        ...getAccountType(accountPaymentType),
        brandId,
        role,
        guestGroupId: subSectionId || sectionId || groupId,
    };
};

const generateCustomerEmailDefaultValue = (badgeNumber: string): string => {
    return `${badgeNumber}@achanger.fr`;
};

const CUSTOMER_PASSWORD_DEFAULT_VALUE = '12345';

export const generateCreateCustomerFormDefaultValuesWithEmailAndPassword = (): Partial<BaseCustomerAttributes> => {
    const badgeNumber = generateBadgeNumber();
    return {
        badgeNumber,
        badges: [{ value: badgeNumber, type: '', isDefault: true }],
        accountPaymentType: ACCOUNT_PAYMENT_TYPE.PREPAYMENT,
        email: generateCustomerEmailDefaultValue(badgeNumber),
        password: CUSTOMER_PASSWORD_DEFAULT_VALUE,
        confirmPassword: CUSTOMER_PASSWORD_DEFAULT_VALUE,
    };
};

export const generateCreateCustomerFormDefaultValues = (): Partial<BaseCustomerAttributes> => {
    const badgeNumber = generateBadgeNumber();
    return {
        badgeNumber,
        accountPaymentType: ACCOUNT_PAYMENT_TYPE.PREPAYMENT,
        badges: [{ value: badgeNumber, type: '', isDefault: true }],
    };
};
