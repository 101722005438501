import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';

import { Routes } from '@/core/router/routes.types';
import { Section } from '@/components/atoms/Section';
import { Table } from '@/components/atoms/Table';
import Loader from '@/components/atoms/Loader';
import DeleteModal from '@/components/atoms/DeleteModal';
import {
    useGetMinimalPricingRulesQuery,
    useDeletePricingRuleMutation,
    useGetRelatedCustomersCountMutation,
} from '@/services/innovorder/pricingRule/pricingRule.endpoints';
import { getUserToken } from '@/redux/user';
import { Button } from '@/components/atoms/Button';
import { getPricingRulesTableRowsVM, getPricingRulesTableColumnsVM } from './utils';
import { AdditionalDescription } from './PricingRules.style';
import Modal from '@/components/atoms/Modal';

interface PricingRulesProps {
    brandId: string;
}

export const PricingRules: React.FunctionComponent<React.PropsWithChildren<PricingRulesProps>> = ({ brandId }) => {
    const intl = useIntl();
    const history = useHistory();
    const token = useSelector(getUserToken);

    const { data, isLoading } = useGetMinimalPricingRulesQuery(
        {
            brandId: parseInt(brandId, 10),
            token,
        },
        { refetchOnMountOrArgChange: true },
    );

    const [getRelatedCustomersCount, { data: relatedCustomersData, isLoading: isLoadingCount }] =
        useGetRelatedCustomersCountMutation();

    const [deletePricingRule, { isSuccess, isLoading: isLoadingDelete }] = useDeletePricingRuleMutation();

    const [idToDelete, setIdToDelete] = React.useState<number | null>(null);

    const handleOpenModal = React.useCallback(
        (id: number): void => {
            getRelatedCustomersCount({ token, pricingRuleId: id });
            setIdToDelete(id);
        },
        [getRelatedCustomersCount, token],
    );

    const handleCloseModal = () => {
        setIdToDelete(null);
    };

    const handleDeletePricingRule = (): void => {
        idToDelete &&
            deletePricingRule({
                pricingRuleId: idToDelete,
                brandId: parseInt(brandId, 10),
                token,
            });
    };

    useEffect(() => {
        if (isSuccess) {
            setIdToDelete(null);
        }
    }, [isSuccess]);

    const handleAddPricingRule = (): void => {
        history.push(generatePath(Routes.CreatePricingRule, { brandId }));
    };

    const pricingRulesTableRows = React.useMemo(
        () => getPricingRulesTableRowsVM(data, handleOpenModal, intl),
        [data, handleOpenModal, intl],
    );
    const pricingRulesTableColumns = React.useMemo(() => getPricingRulesTableColumnsVM(intl), [intl]);

    return (
        <>
            <Section
                title="pricingRule.tableCard.title"
                subtitle="pricingRule.tableCard.subtitle"
                actions={
                    <Button buttonType="primary" onClick={handleAddPricingRule}>
                        <FormattedMessage id="pricingRule.tableCard.action" />
                    </Button>
                }
            >
                {isLoading ? (
                    <Loader withContainer />
                ) : (
                    <Table
                        rows={pricingRulesTableRows}
                        columns={pricingRulesTableColumns}
                        emptyMessageId="pricingRule.emptyMessage"
                        onRowClickPath={Routes.PricingRule}
                        isSearchable={true}
                        searchPlaceholder="pricingRule.search"
                        filters={['code']}
                        hasPagination={true}
                    />
                )}
            </Section>
            {relatedCustomersData && relatedCustomersData.count > 0 ? (
                <Modal
                    isOpen={idToDelete !== null}
                    onHide={handleCloseModal}
                    title="status.error"
                    closeMessage="button.understand"
                >
                    {isLoading ? (
                        <Loader withContainer />
                    ) : (
                        <>
                            <>
                                <FormattedMessage id="pricingRule.modal.delete.error.detail" />
                            </>
                        </>
                    )}
                </Modal>
            ) : (
                <DeleteModal
                    title="pricingRule.modal.warning"
                    description="pricingRule.modal.confirmDelete"
                    isOpen={idToDelete !== null}
                    onHide={handleCloseModal}
                    onSubmit={handleDeletePricingRule}
                    isLoading={isLoadingDelete || isLoadingCount}
                >
                    {isLoadingCount && <Loader withContainer />}
                </DeleteModal>
            )}
        </>
    );
};
