import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getDate, getMonth, getYear } from 'date-fns';

export const formatDate = (date: string | Date, format = 'DD/MM/YYYY'): string => {
    if (!date) return '';

    const internalDate = dayjs(date);

    return internalDate.isValid() ? internalDate.format(format) : '';
};

export const formatDateWithLocal = (date: string | Date, locale = 'fr'): string => {
    const format = locale === 'en' ? 'YYYY-MM-DD' : 'DD/MM/YYYY';
    return formatDate(date, format);
};

export const formatTimestampWithLocal = (timestamp: number, locale = 'fr'): string => {
    const format = locale === 'en' ? 'YYYY-MM-DD HH:mm' : 'DD/MM/YYYY HH:mm';
    return formatDate(dayjs.unix(timestamp).toDate(), format);
};

type DateComponents = { year: number; month: number; day: number };

export function getDateComponents(aDate: Date): DateComponents {
    return {
        year: getYear(aDate),
        month: getMonth(aDate) + 1,
        day: getDate(aDate),
    };
}

export const compareDates = (date1: string, date2: string, format: string): number => {
    dayjs.extend(customParseFormat);

    const parsedDate1 = dayjs(date1, format);
    const parsedDate2 = dayjs(date2, format);

    return parsedDate1.diff(parsedDate2);
};
